import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FileUpload } from '../../models/file-upload';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    basePath = 'files';

    constructor(
        private db: AngularFireDatabase,
        private storage: AngularFireStorage
    ) {}

    uploadBase64(file, filePath, type): Observable<any> {
        const ref = this.storage.ref(filePath);
        const uploadTask = ref.putString(file, 'data_url');
        return uploadTask.snapshotChanges();
    }

    pushFileToStorage(fileUpload: FileUpload, filePath): Observable<any> {
        const uploadTask = this.storage.upload(filePath, fileUpload.file);
        return uploadTask.snapshotChanges();

        // return uploadTask.percentageChanges();
    }

    getDownloadURL(filePath, fileUpload): Observable<any> {
        const storageRef = this.storage.ref(filePath);
        return storageRef.getDownloadURL();
    }

    renameFile(fileName: string): string {
        const extension = fileName.split('?')[0].split('.').pop();
        const newName = fileName.split('?')[0].split('.')[0];
        return `${newName}_${new Date().getTime()}.${extension}`;
    }

    private saveFileData(fileUpload: FileUpload): void {
        this.db.list(this.basePath).push(fileUpload);
    }

    private deleteFileDatabase(key: string): Promise<void> {
        return this.db.list(this.basePath).remove(key);
    }

    private deleteFileStorage(name: string): void {
        const storageRef = this.storage.ref(this.basePath);
        storageRef.child(name).delete();
    }
}
