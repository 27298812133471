import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    focus;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router
    ) {
        this.authService.isLoggedIn().then((isLoggedIn) => {
            console.log(isLoggedIn);
            if (isLoggedIn) {
                this.router.navigate(['news']);
            }
        });
    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });
    }

    submitForm(): void {
        if (this.loginForm.invalid) {
            return;
        }

        this.authService.loginInAdmin(this.loginForm.value).catch((e) => {
            console.log(e);
        });
    }
}
