<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <!--            <h2>About Us</h2>-->
        </div>
    </div>
</div>

<div class="about-area-three pt-30 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="about-content-box">
                    <p>
                        <span class="elementor-drop-cap"
                        ><span class="elementor-drop-cap-letter"
                        >J</span
                        ></span
                        >ohn Deutsch International (JDInt’l) at Queen’s
                        University is an international leader in the design and
                        delivery of executive training programs for
                        professionals in the public and private sectors
                        worldwide. Since 2000, over 1,000 public and private
                        sector professionals from 50 countries have successfully
                        completed its intensive programs.
                    </p>
                    <p>
                        We have had the privilege of preparing several training
                        programs for the staff and clients of multilateral
                        agencies including the World Bank, Asian Development
                        Bank, African Development Bank, Inter-American
                        Development Bank, Southern African Development
                        Community, and Caribbean Development Bank.
                    </p>
                    <p>
                        Governments who have had specially designed courses
                        delivered for them by the faculty of
                        JDInt’l&nbsp;include South Africa, Malaysia, Azerbaijan,
                        Thailand and Ukraine.
                    </p>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="about-img">
                    <div class="image">
                        <img
                            src="https://jdintl.econ.queensu.ca/uploads/Theo-Hall-e1410982660781-768x540.jpg"
                            alt="image"
                        />
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="elementor-text-editor elementor-clearfix">
                    <p>
                        <span class="elementor-drop-cap"
                        ><span class="elementor-drop-cap-letter"
                        >I</span
                        ></span
                        >n addition to our training services, JDInt’l is also
                        involved in the practice of cost-benefit analysis. We
                        have been actively consulting and performing CBA
                        projects to a number of governments and organizations
                        including the World Bank and the African Development
                        Bank. Please visit our
                        <a
                            title="Advisory and Research"
                            href="https://jdintl.econ.queensu.ca/projects/"
                        >Advisory and Research</a
                        >
                        section to learn more about our consulting services
                    </p>
                    <p>
                        JDInt’l has a long standing partnership with
                        <a href="http://cri-world.com/"
                        >Cambridge Resources International, Inc.</a
                        >
                        (CRI). CRI is a consulting firm based in Cambridge, MA,
                        USA, that provides training and advisory services in the
                        areas of Cost-Benefit Analysis, Tax Policy, and Fiscal
                        Administration.We have collaborated on a number of
                        Queen’s-campus initiatives in the past, including the
                        2012 and 2013 Program on Cost-Benefit and
                        Cost-Effectiveness Analysis for USAID.
                    </p>
                    <p>
                        CRI’s senior personnel have worked with international
                        organizations, banks, governments and private
                        corporations from over 30 countries, and are established
                        authorities in their field. Our shared network has
                        allowed us to bring together a great breadth of
                        expertise to the design and delivery of our executive
                        training programs.
                    </p>
                    <p>
                        JDInt’l&nbsp;is located at the Department of Economics,
                        Queen’s University, Kingston ON, Canada.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="advisor-area pt-5 pb-70 bg-f9f9f9">
    <div class="container">
        <div class="section-title">
            <h2>Faculty and Staff</h2>
        </div>
        <div class="row">
            <div
                class="col-sm-12  col-md-6 col-lg-3 "
                *ngFor="let member of faculty"
            >
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="{{member.imageUrl}}" alt="image" />
                    </div>
                    <div class="advisor-content">
                        <h3>
                            {{ member.name }}
                        </h3>
                        <span>{{ member.title }}</span>
                        <span *ngIf="member?.titleTwo">{{
                            member.titleTwo
                            }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--<app-feedback></app-feedback>-->

<!--<app-instructor></app-instructor>-->

<!--<div class="premium-access-area bg-f9f9f9 ptb-100">-->
<!--    <app-premium-access></app-premium-access>-->
<!--    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>-->
<!--    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>-->
<!--    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>-->
<!--</div>-->
