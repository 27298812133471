import { Component, OnInit } from '@angular/core';
import {
    PROGRAM_DATE,
    PROGRAM_YEAR,
} from '../../../../services/api/api.service';

@Component({
    selector: 'app-learning-banner',
    templateUrl: './learning-banner.component.html',
    styleUrls: ['./learning-banner.component.scss'],
})
export class LearningBannerComponent implements OnInit {
    constructor() {}

    protected readonly PROGRAM_DATE = PROGRAM_DATE;
    protected readonly PROGRAM_YEAR = PROGRAM_YEAR;

    ngOnInit(): void {}
}
