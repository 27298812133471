import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../../services/news/news.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
    news: Observable<any[]>;
    showLoading = true;

    constructor(private newsService: NewsService) {}

    ngOnInit(): void {
        this.news = this.newsService.news.pipe(
            map((news) => {
                if (news) {
                    return news.sort((a: any, b: any) => {
                        // @ts-ignore
                        return new Date(b?.news_date) - new Date(a?.news_date);
                    });
                }
                return news;
            })
        );

        setTimeout(() => {
            this.showLoading = false;
        }, 1200);
    }
}
