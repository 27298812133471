import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-details-style-one',
    templateUrl: './courses-details-style-one.component.html',
    styleUrls: ['./courses-details-style-one.component.scss'],
})
export class CoursesDetailsStyleOneComponent implements OnInit {
    public testimonials = [
        {
            name: 'Mr. Paul Karekezi',
            imageUrl:
                'https://jdintl.econ.queensu.ca/wp-content/uploads/2018/08/Paul-Karekezi.jpg',
            comment:
                'An exceptional learning experience! A well-designed program that I would highly recommend to anyone involved in designing projects .',
            position: '  Managing Director',
            company: 'GIBB Africa Ltd, Kenya',
            country: '',
            years: [
                {
                    year: '2018',
                    program: 'PIAR Alumni',
                },
                {
                    year: '2019',
                    program: 'PIAR ToT Alumni',
                },
            ],
        },
        {
            name: 'Assistant Prof. Dr. Mehrshad R Hashemipour ',
            comment:
                'The PIAR training educated me on how to employ economic theory and tools to analyze real investment projects. It enabled me to develop my knowledge and skills in economic and decision analysis.',
            imageUrl:
                'https://jdintl.econ.queensu.ca/wp-content/uploads/2021/04/vesikalik.jpg',
            position: 'Faculty of Economics and Administrative Science ',
            company: 'Cyprus International University',
            country: '',
            years: [
                {
                    year: '2010',
                    program: 'PIAR Alumni',
                },
            ],
        },
        {
            name: 'Mr. Zagros Fatah Siwaily',
            comment:
                'It was truly a different experience of teaching &amp; training, the knowledge we have acquired are practical as we worked on real cases. This is by far the best training I have attended.',
            imageUrl:
                'https://jdintl.econ.queensu.ca/wp-content/uploads/2015/09/Zagros.jpg',
            position: 'Director General for Capital Investment Budget ',
            company: 'Ministry of Planning Iraq, Kurdistan Region',
            country: 'Kurdistan Region',
            years: [
                {
                    year: '2015 ',
                    program: 'PIAR Alumni',
                },
            ],
        },
        {
            name: 'Ms. Cynthia Arthur',
            years: [
                {
                    year: '2019',
                    program: 'PIAR Alumni',
                },
            ],
            position: 'Principle Economist',
            comment:
                'The PIAR program has been an eye-opener and has rekindled my love for project structuring and financing. It brings all aspects of the project into perspective.',
            country: 'Ghana',
            company: 'Ministry of Finance, PIP Unit, Ghana',
            imageUrl:
                'https://jdintl.econ.queensu.ca/wp-content/uploads/2021/04/cynthia.jpeg',
        },
    ];

    public upComingPrograms = [
        {
            title: '',
            center: 'Ghana Institute of Management and Public Administration (GIMPA)',
            date: 'November 8 to December 3, 2021',
            location: 'Accra, Ghana',
            content: `
            <p>
            GIMPA was established in 1961 as a joint Ghana Government/United Nations Special Fund Project. Today, GIMPA has been transformed into a comprehensive tertiary institution offering programs in leadership, management, public and business administration, and technology, for both the public and private sectors.
            </p>
            <p>
            GIMPA is suitably located in a secure, serene wooded environment on the outskirts of Accra. It provides a very welcoming environment for both clients and visitors. The Institute has an ultra-modern Executive Conference Centre well suited for training and conference facilities, well-furnished air-conditioned lecture rooms with modern teaching aids to facilitate teaching and learning.
            </p>
            `,
            imageUrls: [
                {
                    imageUrl:
                        'https://jdintl.econ.queensu.ca/wp-content/uploads/2021/08/Ghana-Institute-of-Management-and-Public-Administration-GIMPA-e1628604530131.jpeg',
                    title: 'GIMPA',
                },
                {
                    imageUrl:
                        'https://jdintl.econ.queensu.ca/wp-content/uploads/2021/08/Beatiful-BSS.jpg',
                    title: 'Accra, Ghana',
                },
            ],
        },
        {
            title: '',
            content: `The island of Cyprus may be small, but thanks to its strategic location in a spectacularly beautiful part of the Mediterranean Sea, it has always had a colorful international character and identity. This was the inspiration for the establishment in 1997 of Cyprus International University. Locating the University just outside the historic capital city of Nicosia in North Cyprus, the plan was to develop an institution that would have an international impact. Twenty-five years later, the university offers over 100 degree programs to around 15,000 students from over 100 different countries. Most recently, the CIU Masterplan for campus development, developed by ARUP, one of the world’s top design, planning, and engineering companies, was awarded first place in the international Urban Design category in the ‘Architecture, Construction and Design Awards 2020’ competition. `,
            date: 'March 7 to April 1, 2022',
            location: 'North Cyprus',
            center: 'Cyprus International University (CIU)',
            imageUrls: [
                {
                    imageUrl:
                        'https://jdintl.econ.queensu.ca/wp-content/uploads/2021/04/Cyprus.jpg',
                    title: 'Kyrenia, North Cyprus',
                },
                {
                    imageUrl:
                        'https://jdintl.econ.queensu.ca/wp-content/uploads/2021/03/ciu-ariel-1536x1025.png',
                    title: 'Cyprus International University',
                },
            ],
        },
        {
            title: '',
            content: `<p>
Queen’s University was established by Royal Charter in 1841 and is one of Canada’s most distinguished universities. Since 2000, the PIAR has offered annually by the John Deutsch International Executive Programs of Queen’s University.
</p>`,
            center: 'Queen’s University in Kingston, Canada',
            location: 'Kingston, Canada',
            date: 'July 4 to July 29, 2022',
            imageUrls: [
                {
                    imageUrl:
                        'https://jdintl.econ.queensu.ca/wp-content/uploads/2021/09/queens.jpg',
                    title: 'Queen’s University',
                },
                {
                    imageUrl:
                        'https://jdintl.econ.queensu.ca/wp-content/uploads/2021/09/canada.png',
                    title: 'Canada',
                },
            ],
        },
    ];

    constructor() {}

    ngOnInit(): void {}
}
