import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-courses-details-style-two',
    templateUrl: './courses-details-style-two.component.html',
    styleUrls: ['./courses-details-style-two.component.scss'],
})
export class CoursesDetailsStyleTwoComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
