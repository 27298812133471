import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-learning-blog',
    templateUrl: './learning-blog.component.html',
    styleUrls: ['./learning-blog.component.scss'],
})
export class LearningBlogComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
