import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, END_POINT } from '../api/api.service';
import { Observable } from 'rxjs';

const PATH = 'apiv2/api/mailer/mailer';

@Injectable({
    providedIn: 'root',
})
export class MailerService {
    constructor(private http: HttpClient, private api: ApiService) {}

    sendApplicationForm(params: any, attachments: any): any {
        return this.http.post(END_POINT + PATH + '/appMailer.php', {
            formData: params,
            attachments,
        });
    }

    sendThankYouMessage(params: any): void {
        this.http
            .post(END_POINT + PATH + '/thankYouMailer.php', {
                formData: params,
            })
            .subscribe(() => {
                console.log('sent');
            });
    }

    sendUpdateForm(params: any, attachments: any): Observable<any> {
        return this.http.post(END_POINT + PATH + '/alumniUpdate.php', {
            formData: params,
            attachments,
        });
    }

    saveUpdateForm(params: any, attachments: any): any {
        return this.api.add('update_request', {
            ...params,
            ...attachments,
        });
    }

    async saveApplicationForm(params: any, attachments: any): Promise<string> {
        const doc = await this.api.add('online_application', {
            ...params,
            ...attachments,
        });
        return doc.id;
    }

    sendContactMessage(value: any): any {
        try {
            this.http
                .post(END_POINT + 'apiv2/mailer/contactUs.php', {
                    formData: value,
                })
                .subscribe();
        } catch (e) {}
        return this.api.add('contactMessage', value);
    }

    updateApplication(docId: string): void {
        this.api.update('online_application/' + docId, {
            status: 'received',
        });
    }
}
