<div class="page-title-area" style="background-image: url('assets/img/about_us_bg.png');">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
        </div>
    </div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-info">
                    <!--                    <span class="sub-title">Contact Details</span>-->
                    <h2>Get in Touch</h2>
                    <p>
                    </p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="bx bx-map"></i>
                            </div>
                            <h3>Address</h3>
                            <p>
                                Queen's University Dunning Hall, <br />
                                Department of Economics, <br />
                                Room 216C, 94 University Avenue, <br />
                                Kingston, Ontario K7L 3N6, Canada
                            </p>
                        </li>
                        <li class="e-contacts">
                            <div class="icon">
                                <em class="bx bx-phone-call"></em>
                            </div>
                            <h3>Contact</h3>
                            <p>

                                <em class="bx bx-phone"></em
                                >
                                <a href="tel:+12063600800"
                                >+1 206 360 0800 </a
                                >
                            </p>
                            <p>
                                <em class="bx bxl-whatsapp"></em
                                >
                                <a target="_blank" href="https://wa.me/+16137707226"
                                >+1 613 770 7226 </a
                                >
                            </p>
                            <p>
                                <em class="bx bx-envelope"></em
                                >
                                <a href="mailto:piar@queensu.ca" target="_blank"
                                >piar@queensu.ca</a
                                >
                            </p>
                        </li>
                        <!--<li>
                            <div class="icon">
                                <i class="bx bx-time-five"></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 09:00 - 20:00</p>
                            <p>Sunday & Saturday: 10:30 - 22:00</p>
                        </li>-->
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <ng-container *ngIf="isMessageSent; else showContactForm">
                    <div class="message-sent card h-300">
                        <div class="card-body card-content d-flex align-items-center m-auto">
                            <h3 class="text-center  ">
                                Thank you for contacting us!
                            </h3>
                        </div>
                    </div>
                </ng-container>
                <ng-template #showContactForm>
                    <div
                        class="contact-form"

                    >
                        <h2>Contact Form</h2>

                        <form
                            #contactForm="ngForm"
                            (ngSubmit)="submit(contactForm.value)"
                        >
                            <div class="row">
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <label for="email">Full Name: </label>

                                        <input
                                            required
                                            minlength="3"
                                            maxlength="50"
                                            ngModel
                                            name="name"
                                            type="text"
                                            #name="ngModel"
                                            class="form-control"
                                            id="name"
                                            placeholder="Your name"
                                        />
                                        <div
                                            class="alert alert-danger"
                                            *ngIf="name.touched && !name.valid"
                                        >
                                            <div *ngIf="name.errors.required">
                                                Name is required.
                                            </div>
                                            <div *ngIf="name.errors.minlength">
                                                Name is minimum
                                                {{
                                                name.errors.minlength
                                                    .requiredLength
                                                }}
                                                character.
                                            </div>
                                            <div *ngIf="name.errors.maxlength">
                                                Name is maximum 50 character.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <label for="email">Email Address: </label>
                                        <input
                                            required
                                            ngModel
                                            name="email"
                                            type="text"
                                            #email="ngModel"
                                            class="form-control"
                                            id="email"
                                            placeholder="Your email address"
                                        />
                                        <div
                                            class="alert alert-danger"
                                            *ngIf="email.touched && !email.valid"
                                        >
                                            Email Address is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="number">Phone number: </label>
                                        <input
                                            required
                                            ngModel
                                            name="number"
                                            type="text"
                                            #number="ngModel"
                                            class="form-control"
                                            id="number"
                                            placeholder="Your phone number"
                                        />
                                        <div
                                            class="alert alert-danger"
                                            *ngIf="number.touched && !number.valid"
                                        >
                                            Phone number is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label for="message">Message: </label>
                                        <textarea
                                            required
                                            ngModel
                                            #message="ngModel"
                                            name="message"
                                            id="message"
                                            cols="30"
                                            rows="5"
                                            class="form-control"
                                            placeholder="Write your message..."
                                        ></textarea>
                                        <div
                                            class="alert alert-danger"
                                            *ngIf="message.touched && !name.valid"
                                        >
                                            Message is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button
                                        type="submit"
                                        class="default-btn"
                                        [class.disabled]="!contactForm.valid"
                                        [disabled]="!contactForm.valid"
                                    >
                                        Send Message<span></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
