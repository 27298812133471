import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Router } from '@angular/router';
import {
    Auth,
    authState,
    signInWithEmailAndPassword,
} from '@angular/fire/auth';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private api: ApiService,
        private auth: Auth,
        private router: Router
    ) {}

    async isLoggedIn(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            authState(this.auth).subscribe((user) => {
                resolve(!!user);
            });
        });
    }

    loginInAdmin(params: { email: string; password: string }): any {
        console.log(params);
        return signInWithEmailAndPassword(
            this.auth,
            params.email,
            params.password
        );
    }

    logOut(): void {
        this.auth.signOut().then(() => {
            this.router.navigate(['/']);
        });
    }
}
