<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">Development</a></li>
                <li>
                    Python for Finance: Investment Fundamentals & Data Analytics
                </li>
            </ul>
            <h2>Program on Investment Appraisal and Risk Analysis (PIAR)</h2>
            <!-- <div class="rating">
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <div class="rating-count">
                     <span>4.0 (1 rating)</span>
                 </div>
             </div>-->
        </div>
    </div>
    <div class="shape9"><img alt="image" src="assets/img/shape8.svg"/></div>
</div>

<div class="courses-details-area pb-100">
    <!--    <div class="courses-details-image">-->
    <!--        <img alt="image" src="assets/img/courses/course-details.jpg" />-->
    <!--    </div>-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Overview">
                            <div class="courses-overview">
                                <p>
                                    For the 21st year, Queen’s University is
                                    offering its state-of-the-art 4-week program
                                    on Investment Appraisal and Risk Analysis
                                    for professionals working in public and
                                    private organizations. With the recent surge
                                    of infections worldwide associated with the
                                    Delta variant of the COVID-19 virus, and the
                                    frequent changes in the restrictions imposed
                                    by the governments on travel, it has become
                                    a challenge for many applicants in 2021 to
                                    attend PIAR in Canada. As a result, we have
                                    decided to hold the program of Investment
                                    Appraisal and Risk Analysis in three
                                    locations during 2021 and 2022. Queen’s PIAR
                                    faculty team will be conducting the course
                                    in all locations. The curriculum and the
                                    method of training will be identical to that
                                    of the Regular and Training of Trainers
                                    Programs normally held at the campus of
                                    Queen’s University. The Certificate on
                                    Investment Appraisal and Risk Analysis will
                                    be issued upon successful completion of the
                                    program.
                                </p>
                                <p>
                                    Participants will learn how to conduct the
                                    Integrated Analysis of Investment Projects
                                    including financial, economic, risk and
                                    stakeholder analysis. They will gain skills
                                    in the design and testing of risk management
                                    contracts, public private partnerships
                                    (PPPs), and the evaluation of project
                                    financing options.
                                </p>
                                <p>
                                    Through lectures by internationally
                                    recognized experts, applied case studies,
                                    and group discussions, participants will
                                    gain practical knowledge, and obtain a
                                    global perspective of recent developments in
                                    these fields.
                                </p>
                                <p>
                                    Professionals who have undergone training in
                                    Investment Appraisal are eligible for our
                                    Training of Trainers stream.
                                </p>
                                <h3>Goals</h3>
                                <p>
                                    The goals of the program are to develop the
                                    operational skills of participants so that
                                    they are able to:
                                </p>
                                <ul class="requirements-list">
                                    <li>
                                        Plan and assess all aspects of
                                        investment projects
                                    </li>
                                    <li>
                                        Add value through the redesign of
                                        projects
                                    </li>
                                    <li>
                                        Prepare project financing agreements
                                    </li>
                                    <li>
                                        Design strategies to address stakeholder
                                        concerns
                                    </li>
                                    <li>Develop sustainable PPP models</li>
                                </ul>

                                <h3>Participants</h3>
                                This program has been crafted for people who
                                design, select, or finance projects. The
                                acquired operational skills have proven to be
                                effective for the career development of
                                individuals in a wide range of organizations,
                                including:
                                <ul class="requirements-list">
                                    <li>Public and private enterprises</li>
                                    <li>Banks and financial institutions</li>
                                    <li>
                                        Government ministries and regulatory
                                        agencies
                                    </li>
                                    <li>International development agencies</li>
                                    <li>
                                        Management, engineering, and business
                                        consulting firms
                                    </li>
                                </ul>
                                <p>
                                    Participants of past programs include CEOs
                                    of utilities and infrastructure firms,
                                    directors of government ministries,
                                    professionals from international financial
                                    organizations such as the World Bank, the
                                    European Bank for Reconstruction, the
                                    Inter-American Development Bank, the African
                                    Development Bank, the Caribbean Development
                                    Bank, the Asian Development Bank, and the
                                    International Finance Corporation. Our
                                    website maintains a record of our
                                    distinguished alumni and testimonies from
                                    our past participants on the relevance of
                                    this program to their work and career
                                    development. Visit the
                                    <a
                                        href="https://jdintl.econ.queensu.ca/alumni/"
                                        title="Alumni"
                                    >Alumni</a
                                    >
                                    section of our website to learn more about
                                    our past programs and their participants.
                                </p>

                                <h3>Study Method</h3>
                                <ul class="requirements-list">
                                    <li>Application of analytical tools</li>
                                    <li>
                                        Balanced combination of lectures,
                                        discussion groups, case studies, and
                                        computer-based exercises
                                    </li>
                                    <li>
                                        Individual guidance from international
                                        teaching staff
                                    </li>
                                </ul>
                                <p class="p3">
                                    The program places emphasis on the
                                    integrated approach for investment
                                    appraisal. This methodology allows the
                                    analyst to use a single model to evaluate a
                                    project from different points of view
                                    including, but not limited to, the private
                                    operator, as well as the fiscal, social, and
                                    environmental impacts.
                                </p>
                                <h3>Costs and Funding</h3>
                                <p class="p3">
                                    The cost for this 4-week program is US$
                                    13,000. This fee includes tuition, housing,
                                    health insurance, course materials, computer
                                    facilities, software, and all social events,
                                    but does not include airfare and other meal
                                    costs. Sponsorship is normally provided by
                                    the participant’s employer or by other
                                    funding organizations. Candidates should
                                    approach their current employer or other
                                    funding organizations in advance in order to
                                    obtain financial support.
                                </p>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Course Outline">
                            <div
                                class="
                                    courses-curriculum
                                    courses-details-desc-style-two
                                "
                            >
                                <p>
                                    <strong>
                                        The curriculum covers the Integrated
                                        Investment Appraisal Methodology,
                                        including the financial, risk, economic,
                                        and stakeholder analysis. Topics
                                        include:
                                    </strong>
                                </p>

                                <h3>Financial Modeling:</h3>
                                <ul class="requirements-list">
                                    <li>Project environment</li>
                                    <li>
                                        Stages in development of feasibility
                                        studies
                                    </li>
                                    <li>Development of project cash flows</li>
                                    <li>
                                        Different perspectives and project
                                        sustainability
                                    </li>
                                    <li>
                                        Projection of real prices through time
                                    </li>
                                    <li>
                                        Insuring consistency among nominal
                                        prices, exchange rates, and interest
                                        rates
                                    </li>
                                    <li>Evaluation of debt service capacity</li>
                                    <li>
                                        Uses and limitations of different
                                        investment criteria
                                    </li>
                                    <li>Scale and timing decisions</li>
                                    <li>Privatization and regulation issues</li>
                                </ul>
                                <h3>Risk Analysis and Project Finance:</h3>
                                <ul class="requirements-list">
                                    <li>
                                        Assessing sources and nature of project
                                        variability
                                    </li>
                                    <li>Risk assessment techniques</li>
                                    <li>
                                        Monte Carlo Simulation using Crystal
                                        Ball
                                    </li>
                                    <li>Quantification of risk variables</li>
                                    <li>Project financing</li>
                                    <li>Public-Private Partnerships</li>
                                    <li>Risk management</li>
                                    <li>
                                        Management of Contingent Liabilities
                                    </li>
                                    <li>
                                        Evaluation of contracts and Project
                                        Viability
                                    </li>
                                    <li>Public investment management</li>
                                </ul>
                                <h3>Economic Analysis:</h3>
                                <ul class="requirements-list">
                                    <li>
                                        Economic valuation of marketed and
                                        non-marketed goods and services
                                    </li>
                                    <li>
                                        Economic opportunity cost of capital
                                    </li>
                                    <li>Economic cost of foreign exchange</li>
                                    <li>Economic cost of human resources</li>
                                    <li>Cost effectiveness analysis</li>
                                    <li>
                                        Economic analysis of projects in
                                        different<br/>sectors including power,
                                        education,<br/>transportation, health,
                                        agriculture, water,<br/>and
                                        telecommunication
                                    </li>
                                </ul>
                                <h3>Environmental Analysis:</h3>
                                <ul class="requirements-list">
                                    <li>
                                        Economic valuation of environmental
                                        impacts
                                    </li>
                                    <li>
                                        Integration of environmental analysis
                                        with project appraisal
                                    </li>
                                    <li>
                                        Investment Appraisal with impacts of
                                        climate change
                                    </li>
                                </ul>
                                <h3>Stakeholder Impact Assessment:</h3>
                                <ul class="requirements-list">
                                    <li>Identification of stakeholders</li>
                                    <li>
                                        Measurement of projects’ impact on each
                                        group of stakeholders
                                    </li>
                                    <li>
                                        Social analysis of projects: basic needs
                                        analysis
                                    </li>
                                </ul>
                                <ul class="audience-list">
                                    <li>
                                        Beginners to programming and data
                                        science
                                    </li>
                                    <li>
                                        Students eager to learn about job
                                        opportunities in the field of data
                                        science
                                    </li>
                                    <li>
                                        Candidates willing to boost their resume
                                        by learning how to combine the knowledge
                                        of Statistics, SQL, and Tableau in a
                                        real-world working environment
                                    </li>
                                    <li>
                                        People interested in a Business
                                        Intelligence Analyst career
                                    </li>
                                </ul>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Upcoming Programs">
                            <div class="faq-accordion">
                                <accordion>
                                    <ng-container
                                        *ngFor="let program of upComingPrograms"
                                    >
                                        <accordion-group>
                                            <accordion-heading>
                                                <a class="" role="button">
                                                    {{ program.center }} <br/>
                                                    <span class="date">
                                                        <i
                                                            class="
                                                                bx bx-calendar
                                                            "
                                                        ></i>
                                                        {{ program.date }}
                                                    </span>
                                                </a>
                                            </accordion-heading>
                                            <div
                                                [innerHTML]="program.content"
                                                class="mb-4"
                                            ></div>
                                            <div class="row">
                                                <div
                                                    class="col-md-6 col-sm-12"
                                                    *ngFor="
                                                        let image of program.imageUrls
                                                    "
                                                >
                                                    <img
                                                        alt="{{ image.title }}"
                                                        class="img-responsive"
                                                        src="{{
                                                            image.imageUrl
                                                        }}"
                                                    />
                                                    <span class="sub-heading">
                                                        {{ image.title }}
                                                    </span>
                                                </div>
                                            </div>
                                        </accordion-group>
                                    </ng-container>
                                </accordion>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Testimonials">
                            <div class="courses-instructor testimonials">
                                <ng-container
                                    *ngFor="let testimonial of testimonials"
                                >
                                    <div class="single-advisor-box">
                                        <div class="row align-items-center">
                                            <div class="col-lg-4 col-md-4">
                                                <div class="advisor-image">
                                                    <img
                                                        alt="image"
                                                        src="{{
                                                            testimonial.imageUrl
                                                        }}"
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-md-8">
                                                <div class="advisor-content">
                                                    <h3>
                                                        {{ testimonial.name }}
                                                    </h3>
                                                    <span class="sub-title">
                                                        {{
                                                        testimonial.position
                                                        }}
                                                        <br/>
                                                        {{
                                                        testimonial.company
                                                        }}
                                                        <br/>
                                                        <ng-container
                                                            *ngFor="
                                                                let alumni of testimonial.years;
                                                                let i = index
                                                            "
                                                        >
                                                            {{ alumni.year }}
                                                            {{ alumni.program }}
                                                            <ng-container
                                                                *ngIf="
                                                                    i + 1 !==
                                                                    testimonial
                                                                        ?.years
                                                                        .length
                                                                "
                                                            >
                                                                <br/>
                                                            </ng-container>
                                                        </ng-container>
                                                        <br/>
                                                    </span>
                                                    <p
                                                        [innerHTML]="
                                                            testimonial.comment
                                                        "
                                                    ></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    <div class="image">
                        <img alt="image" src="assets/img/courses/img1.jpg"/>

                        <!--                         <div class="content">-->
                        <!--                             <i class="flaticon-play"></i>-->
                        <!--                             <span>Course Preview</span>-->
                        <!--                         </div>-->
                    </div>

                    <div class="info">
                        <h3>How to apply</h3>
                        <p>
                            We strongly encourage you to use our online
                            application form. Applications may also be submitted
                            by email, fax, or mail. Please download our offline
                            application form if you chose not to apply online.
                        </p>
                        <p>
                            To ensure smooth communication, please provide a
                            postal address and a street address, for both home
                            and office (where different), office and home phone
                            and fax numbers wherever possible, and an email
                            address, if available.
                        </p>
                    </div>
                    <div class="content info"></div>
                    <div class="btn-box">
                        <a class="default-btn" routerLink="/single-courses-1">
                            Apply Now
                            <span></span>
                        </a>

                        <a routerlink="/single-courses-1" class="default-btn">
                            Download Our Brochure<span></span
                        ></a>
                    </div>
                    <!--<div class="courses-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>
                            <ul class="social-link">
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
