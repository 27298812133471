<div class="start-with-success-area pt-100 bg-f8f9f8 pb-70">
    <div class="container">
        <div class="section-title">
            <!--            <span class="sub-title">{{ pageTitle }}</span>-->
            <h2>{{ programCategory }}</h2>
        </div>
        <!--<div class="row tw-justify-end tw-mb-[5rem]">
            <div class="col-md-4 col-sm-12 ">
                <input type="search" class="form-control tw-border-1 tw-border-gray-700"
                       [(ngModel)]="searchTerm"
                       (input)="searchAlumni($event)"
                       placeholder="Search alumni in {{programCategory}}" />
            </div>
        </div>-->
        <div class="row row-cols-1 row-cols-md-4 row-cols-sm-12 g-2">
            <div class="col" *ngFor="let item of alumni | async">
                <div class="start-with-success-box card h-100">
                    <div class="image">
                        <a
                            routerLink="{{ item.typeSlug }}"
                            [queryParams]="{ ct: item.id, type: item.typeSlug }"
                        >
                            <img
                                ngSrc="{{item?.groupPicture}}"
                                height="200"
                                width="295"
                                class="img-responsive group-image"
                                alt="image"
                                loading="lazy"
                            />
                        </a>
                    </div>
                    <div class="content">
                        <a
                            routerLink="{{ item.typeSlug }}"
                            [queryParams]="{ ct: item.id, type: item.typeSlug }"
                            class="link-btn"
                        ><i class="flaticon-right"></i
                        ></a>
                        <h3>{{ item.year }} {{ item.type }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
