<div class="profile-authentication-area tw-flex tw-items-center tw-justify-center m-auto tw-h-[100vh] ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <h2>Login</h2>
                    <form role="form" [formGroup]="loginForm">
                        <div class="form-group">
                            <label>Username or email</label>
                            <input
                                type="email"
                                formControlName="email"
                                class="form-control"
                                placeholder="email"
                            />
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                class="form-control"
                                formControlName="password"
                                placeholder="Password"
                            />
                        </div>
                        <div class="row align-items-center">
                            <div
                                class="
                                    col-lg-6 col-md-6 col-sm-6
                                    remember-me-wrap
                                "
                            >
                                <p>
                                    <input type="checkbox" id="test2" />
                                    <label for="test2">Remember me</label>
                                </p>
                            </div>
                            <div
                                class="
                                    col-lg-6 col-md-6 col-sm-6
                                    lost-your-password-wrap
                                "
                            >
                                <!-- <a routerLink="/" class="lost-your-password"
                                 >Lost your password?</a
                                 >-->
                            </div>
                        </div>
                        <button type="button" (click)="submitForm()">Log In</button>
                    </form>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12">
                 <div class="register-form">
                     <h2>Register</h2>
                     <form>
                         <div class="form-group">
                             <label>Username</label>
                             <input
                                 type="text"
                                 class="form-control"
                                 placeholder="Username or email"
                             />
                         </div>
                         <div class="form-group">
                             <label>Email</label>
                             <input
                                 type="email"
                                 class="form-control"
                                 placeholder="Username or email"
                             />
                         </div>
                         <div class="form-group">
                             <label>Password</label>
                             <input
                                 type="password"
                                 class="form-control"
                                 placeholder="Password"
                             />
                         </div>
                         <p class="description">
                             The password should be at least eight characters
                             long. To make it stronger, use upper and lower case
                             letters, numbers, and symbols like ! " ? $ % ^ & )
                         </p>
                         <button type="submit">Register</button>
                     </form>
                 </div>
             </div>-->
        </div>
    </div>
</div>
