import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-learning-about',
    templateUrl: './learning-about.component.html',
    styleUrls: ['./learning-about.component.scss'],
})
export class LearningAboutComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
