import { Component, OnInit } from '@angular/core';
import { PROGRAM_DATE, PROGRAM_YEAR } from '../../../services/api/api.service';

@Component({
    selector: 'app-features-style-four',
    templateUrl: './features-style-four.component.html',
    styleUrls: ['./features-style-four.component.scss'],
})
export class FeaturesStyleFourComponent implements OnInit {
    constructor() {}

    protected readonly PROGRAM_DATE = PROGRAM_DATE;
    protected readonly PROGRAM_YEAR = PROGRAM_YEAR;

    ngOnInit(): void {}
}
