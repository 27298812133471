<aside class="dark:tw-bg-gray-800 tw-rounded sidebar" aria-label="Sidebar">
    <div
        class="tw-overflow-y-auto tw-py-4 tw-px-3 tw-bg-gray-50 dark:tw-bg-gray-800 tw-h-[inherit] sidebar-item-wrapper"
    >
        <a href="/" class="tw-flex tw-items-center">
            <span
                class="tw-self-center tw-text-xl tw-font-semibold tw-whitespace-nowrap dark:tw-text-white"
            >Dashboard</span
            >
        </a>
        <ul class="side-bar-menu">
            <li *ngFor="let item of menuItems; let i = index">
                <ng-container *ngIf="item?.subMenu">
                    <div class="menu-item" (click)="toggleSubMenu(i)">
                        {{ item.title }}
                        <i
                            [ngClass]="{
                                ' bx-chevron-down': !item.isSubMenuVisible,
                                ' bx-chevron-up': item.isSubMenuVisible
                            }"
                            class="bx tw-right-0"
                        ></i>
                    </div>
                    <ul
                        class="submenu"
                        [style.display]="
                            item.isSubMenuVisible ? 'block' : 'none'
                        "
                    >
                        <li *ngFor="let subItem of item.subMenu">
                            <a [routerLink]="subItem.link" class="menu-link">{{
                                subItem.title
                                }}</a>
                        </li>
                    </ul>
                </ng-container>
            </li>
        </ul>
        <ul class="log-out">
            <li>
                <a
                    (click)="logOut()"
                    class="menu-item tw-cafe-btn tw-cafe-btn-outline tw-cafe-btn-sm"
                >
                    <i class="fa fa-sign-out"></i>
                </a>
            </li>
        </ul>
    </div>
</aside>
