<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">Development</a></li>
                <li>
                    Python for Finance: Investment Fundamentals & Data Analytics
                </li>
            </ul>
            <h2>
                Python for Finance: Investment Fundamentals & Data Analytics
            </h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="courses-details-area ptb-100">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="courses-title">
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy.
                        </p>
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class="bx bx-folder-open"></i>
                                <span>Category</span>
                                <a routerLink="/courses-1">Photography</a>
                            </li>
                            <li>
                                <i class="bx bx-group"></i>
                                <span>Students Enrolled</span>
                                <a routerLink="/single-courses-2">541,214</a>
                            </li>
                            <li>
                                <i class="bx bx-calendar"></i>
                                <span>Last Updated</span>
                                <a routerLink="/single-courses-2">01/14/2020</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="courses-price">
                        <div class="courses-review">
                            <div class="review-stars">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                            </div>
                            <span class="reviews-total d-inline-block"
                                >(3 reviews)</span
                            >
                        </div>
                        <div class="price">$150</div>
                        <a routerLink="/single-courses-2" class="default-btn"
                            ><i class="flaticon-user"></i>Buy Course<span></span
                        ></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-image-style-two text-center">
                    <img src="assets/img/courses/img2.jpg" alt="image" />
                </div>
                <div class="courses-details-desc-style-two">
                    <h3>Description</h3>
                    <p>
                        <strong
                            >Hi! Welcome to Photography Crash Course for
                            Photographer, the only course you need to become a
                            BI Analyst.</strong
                        >
                    </p>
                    <p>
                        Here are some more details of what you get with The
                        Business Intelligence Analyst Course:
                    </p>
                    <ul class="description-features-list">
                        <li>
                            <strong
                                >Introduction to Data and Data Science</strong
                            >
                            – Make sense of terms like business intelligence,
                            traditional and big data, traditional statistical
                            methods, machine learning, predictive analytics,
                            supervised learning, unsupervised learning,
                            reinforcement learning, and many more;
                        </li>
                        <li>
                            <strong>Database theory</strong> – Before you start
                            using SQL, it is highly beneficial to learn about
                            the underlying database theory and acquire an
                            understanding of why databases are created and how
                            they can help us manage data;
                        </li>
                        <li>
                            <strong>SQL</strong> – when you can work with SQL,
                            it means you don’t have to rely on others sending
                            you data and executing queries for you. You can do
                            that on your own. This allows you to be independent
                            and dig deeper into the data to obtain the answers
                            to questions that might improve the way your company
                            does its business;
                        </li>
                    </ul>
                    <h3>Courses Video</h3>
                    <div class="courses-curriculum">
                        <h3>Python Introduction</h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >Python Introduction</span
                                    >
                                    <div class="courses-meta">
                                        <span class="questions"
                                            >5 questions</span
                                        >
                                        <span class="duration">01 Hour</span>
                                        <span class="status">Preview</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <h3>Stepping into the World of Python</h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        locked
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >NumPy Introduction</span
                                    >
                                    <div class="courses-meta">
                                        <span class="duration">15 Min</span>
                                        <span class="status locked"
                                            ><i class="flaticon-password"></i
                                        ></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        locked
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >NumPy Getting Started</span
                                    >
                                    <div class="courses-meta">
                                        <span class="duration">30 Min</span>
                                        <span class="status locked"
                                            ><i class="flaticon-password"></i
                                        ></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        locked
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >NumPy Creating Arrays</span
                                    >
                                    <div class="courses-meta">
                                        <span class="duration">45 Min</span>
                                        <span class="status locked"
                                            ><i class="flaticon-password"></i
                                        ></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        locked
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >NumPy Array Indexing</span
                                    >
                                    <div class="courses-meta">
                                        <span class="questions"
                                            >4 questions</span
                                        >
                                        <span class="duration">1 Hour</span>
                                        <span class="status locked"
                                            ><i class="flaticon-password"></i
                                        ></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        locked
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >NumPy Array Slicing</span
                                    >
                                    <div class="courses-meta">
                                        <span class="duration">1.5 Hour</span>
                                        <span class="status locked"
                                            ><i class="flaticon-password"></i
                                        ></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <h3>Python MySQL</h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        locked
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >Python MySQL</span
                                    >
                                    <div class="courses-meta">
                                        <span class="duration">01 Hour</span>
                                        <span class="status locked"
                                            ><i class="flaticon-password"></i
                                        ></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        locked
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >Python MySQL Create Database</span
                                    >
                                    <div class="courses-meta">
                                        <span class="questions"
                                            >3 questions</span
                                        >
                                        <span class="duration">1.1 Hour</span>
                                        <span class="status locked"
                                            ><i class="flaticon-password"></i
                                        ></span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                                    class="
                                        locked
                                        popup-youtube
                                        d-flex
                                        justify-content-between
                                        align-items-center
                                    "
                                >
                                    <span class="courses-name"
                                        >Python MySQL Create Table</span
                                    >
                                    <div class="courses-meta">
                                        <span class="duration">1.5 Hour</span>
                                        <span class="status locked"
                                            ><i class="flaticon-password"></i
                                        ></span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <h3>What you'll learn</h3>
                    <div class="why-you-learn">
                        <ul>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Become an expert in Statistics
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Boost your resume with skills
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Gather, organize, data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Use data for improved
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Present information KPIs
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Perform quantitative
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Analyze current data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Discover how to find trends
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Understand the fundamentals
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="flaticon-tick"></i>
                                    Use SQL to create, design
                                </span>
                            </li>
                        </ul>
                    </div>
                    <h3>Requirements</h3>
                    <ul class="requirements-list">
                        <li>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text.
                        </li>
                        <li>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry.
                        </li>
                        <li>
                            The standard Lorem Ipsum passage, used since the
                            1500s.
                        </li>
                    </ul>
                    <h3>Who this course is for:</h3>
                    <ul class="audience-list">
                        <li>Beginners to programming and data science</li>
                        <li>
                            Students eager to learn about job opportunities in
                            the field of data science
                        </li>
                        <li>
                            Candidates willing to boost their resume by learning
                            how to combine the knowledge of Statistics, SQL, and
                            Tableau in a real-world working environment
                        </li>
                        <li>
                            People interested in a Business Intelligence Analyst
                            career
                        </li>
                    </ul>
                    <h3>Meet Your Instructors</h3>
                    <div class="courses-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img
                                    src="assets/img/user1.jpg"
                                    class="shadow-sm rounded-circle"
                                    alt="image"
                                />
                                <div class="author-profile-title-details">
                                    <div class="author-profile-details">
                                        <h4>James Anderson</h4>
                                        <span class="d-block"
                                            >Photographer, Author, Teacher</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <p>
                                James Anderson is a celebrated photographer,
                                author, and teacher who brings passion to
                                everything he does.
                            </p>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s.
                            </p>
                        </div>
                    </div>
                    <div class="courses-reviews">
                        <h3>Course Rating</h3>
                        <div class="rating">
                            <span class="bx bxs-star checked"></span>
                            <span class="bx bxs-star checked"></span>
                            <span class="bx bxs-star checked"></span>
                            <span class="bx bxs-star checked"></span>
                            <span class="bx bxs-star"></span>
                        </div>
                        <div class="rating-count">
                            <span>4.1 average based on 4 reviews.</span>
                        </div>
                        <div class="row">
                            <div class="side">
                                <div>5 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-5"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>02</div>
                            </div>
                            <div class="side">
                                <div>4 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-4"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>03</div>
                            </div>
                            <div class="side">
                                <div>3 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-3"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>04</div>
                            </div>
                            <div class="side">
                                <div>2 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-2"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>05</div>
                            </div>
                            <div class="side">
                                <div>1 star</div>
                            </div>
                            <div class="middle">
                                <div class="bar-container">
                                    <div class="bar-1"></div>
                                </div>
                            </div>
                            <div class="side right">
                                <div>00</div>
                            </div>
                        </div>
                    </div>
                    <div class="courses-review-comments">
                        <h3>3 Reviews</h3>
                        <div class="user-review">
                            <img src="assets/img/user1.jpg" alt="image" />
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                </div>
                                <span class="d-inline-block"
                                    >James Anderson</span
                                >
                            </div>
                            <span class="d-block sub-comment">Excellent</span>
                            <p>
                                Very well built theme, couldn't be happier with
                                it. Can't wait for future updates to see what
                                else they add in.
                            </p>
                        </div>
                        <div class="user-review">
                            <img src="assets/img/user2.jpg" alt="image" />
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star"></i>
                                    <i class="bx bxs-star"></i>
                                </div>
                                <span class="d-inline-block">Sarah Taylor</span>
                            </div>
                            <span class="d-block sub-comment"
                                >Video Quality!</span
                            >
                            <p>
                                Was really easy to implement and they quickly
                                answer my additional questions!
                            </p>
                        </div>
                        <div class="user-review">
                            <img src="assets/img/user3.jpg" alt="image" />
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                </div>
                                <span class="d-inline-block">David Warner</span>
                            </div>
                            <span class="d-block sub-comment"
                                >Perfect Coding!</span
                            >
                            <p>
                                Stunning design, very dedicated crew who welcome
                                new ideas suggested by customers, nice support.
                            </p>
                        </div>
                        <div class="user-review">
                            <img src="assets/img/user4.jpg" alt="image" />
                            <div class="review-rating">
                                <div class="review-stars">
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star checked"></i>
                                    <i class="bx bxs-star"></i>
                                </div>
                                <span class="d-inline-block">King Kong</span>
                            </div>
                            <span class="d-block sub-comment"
                                >Perfect Video!</span
                            >
                            <p>
                                Stunning design, very dedicated crew who welcome
                                new ideas suggested by customers, nice support.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-sidebar-information">
                    <ul class="info">
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-teacher"></i>
                                    Instructor</span
                                >
                                James Anderson
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-time"></i>
                                    Duration</span
                                >
                                7 weeks
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-distance-learning"></i>
                                    Lessons</span
                                >
                                25
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-web"></i> Enrolled</span
                                >
                                255 students
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-html"></i>
                                    Language</span
                                >
                                English
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-caption"></i> Video
                                    Subtitle</span
                                >
                                English
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-lock"></i> Access</span
                                >
                                Lifetime
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-quiz"></i> Quizzes</span
                                >
                                Yes
                            </div>
                        </li>
                        <li>
                            <div
                                class="
                                    d-flex
                                    justify-content-between
                                    align-items-center
                                "
                            >
                                <span
                                    ><i class="flaticon-certification"></i>
                                    Certificate</span
                                >
                                Yes
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/single-courses-2" class="default-btn"
                            ><i class="flaticon-shopping-cart"></i>Add to
                            Cart<span></span
                        ></a>
                    </div>
                    <div class="courses-share">
                        <div class="share-info">
                            <span
                                >Share This Course <i class="flaticon-share"></i
                            ></span>
                            <ul class="social-link">
                                <li>
                                    <a href="#" class="d-block" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="d-block" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="d-block" target="_blank"
                                        ><i class="bx bxl-instagram"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" class="d-block" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="courses-area bg-f8f9f8 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>More Courses You Might Like</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img
                                src="assets/img/courses/img1.jpg"
                                alt="image"
                            />
                        </a>
                        <a routerLink="/" class="fav"
                            ><i class="flaticon-heart"></i
                        ></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img
                                src="assets/img/user1.jpg"
                                class="rounded-circle"
                                alt="image"
                            />
                            <span>Alex Morgan</span>
                        </div>
                        <h3>
                            <a routerLink="/single-courses-1"
                                >The Data Science Course 2020: Complete Data
                                Science Bootcamp</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                        </p>
                        <ul
                            class="
                                courses-box-footer
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <li><i class="flaticon-agenda"></i> 15 Lessons</li>
                            <li>
                                <i class="flaticon-people"></i> 145 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img
                                src="assets/img/courses/img2.jpg"
                                alt="image"
                            />
                        </a>
                        <a routerLink="/" class="fav"
                            ><i class="flaticon-heart"></i
                        ></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img
                                src="assets/img/user2.jpg"
                                class="rounded-circle"
                                alt="image"
                            />
                            <span>Sarah Taylor</span>
                        </div>
                        <h3>
                            <a routerLink="/single-courses-1"
                                >Java Programming Masterclass for Software
                                Developers</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                        </p>
                        <ul
                            class="
                                courses-box-footer
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <li><i class="flaticon-agenda"></i> 20 Lessons</li>
                            <li>
                                <i class="flaticon-people"></i> 100 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img
                                src="assets/img/courses/img3.jpg"
                                alt="image"
                            />
                        </a>
                        <a routerLink="/" class="fav"
                            ><i class="flaticon-heart"></i
                        ></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img
                                src="assets/img/user3.jpg"
                                class="rounded-circle"
                                alt="image"
                            />
                            <span>David Warner</span>
                        </div>
                        <h3>
                            <a routerLink="/single-courses-1"
                                >Deep Learning A-Z™: Hands-On Artificial Neural
                                Networks</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                        </p>
                        <ul
                            class="
                                courses-box-footer
                                d-flex
                                justify-content-between
                                align-items-center
                            "
                        >
                            <li><i class="flaticon-agenda"></i> 20 Lessons</li>
                            <li>
                                <i class="flaticon-people"></i> 150 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
