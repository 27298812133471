import { Component, OnInit } from '@angular/core';
import { PROGRAM_DATE } from '../../../services/api/api.service';

@Component({
    selector: 'app-piar',
    templateUrl: './piar.component.html',
    styleUrls: ['./piar.component.scss'],
})
export class PiarComponent implements OnInit {
    constructor() {}

    public testimonials = [
        {
            name: 'Mr. Patrick Khulekani Dlamini',
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/testimonial/Patrick.png',
            comment:
                'It is an empowering and unique program. It was very beneficial for me. I wish I had known of it earlier on. It is a must-course for Investment professionals to make an impact in their countries and organizations .',
            position: '  Chief Executive Officer and Managing Director',
            company:
                'Development Bank of Southern Africa, Johannesburg, South Africa',
            country: 'Johannesburg, South Africa',
            years: [
                {
                    year: '2022 ',
                    program: 'PIAR Alumni',
                },
            ],
        },
        {
            name: 'Ms. Tonya Millar',
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/testimonial/Tonya-Millar.png',
            comment:
                'The program was engaging, thought-provoking and highly relevant to my role as a Financial Analyst in a development bank. The case studies allowed for the practical application of the course concepts. I am confident that the course has enhanced my ability to contribute to national, regional and international development.',
            position: 'Operations Officer (Financial Analyst)',
            company: 'Caribbean Development Bank, Barbados',
            country: '',
            years: [
                {
                    year: '2022',
                    program: 'PIAR Alumni',
                },
            ],
        },
        {
            name: 'Ms. Camille Parker',
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/testimonial/Camille-Parker.png',
            comment:
                'The program is a fantastic opportunity to build expertise in Cost-Benefit Analysis while connecting with professionals worldwide. The instructors are excellent and go out of their way to ensure that everyone is learning and having a good experience.',
            position: 'Economist',
            company:
                'US Agency for International Development,\n' +
                'United States of America',
            country: '',
            years: [
                {
                    year: '2022',
                    program: 'PIAR Alumni',
                },
            ],
        },
        {
            name: 'Dr. Edgardo S. Mimica',
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/testimonial/Edgardo-S-Mimica.png',
            comment:
                'As a senior public servant and academician, I have been associated with Project Appraisal for most of my professional life, but the four weeks I spent in the Investment Appraisal program at Queen’s University raised my professional understanding to a whole new level. It maintains the right balance between state-of-the-art analytics and a practical hands-on approach',
            position: 'Director of Master Program in Project',
            company:
                'Evaluation and Management Adolfo Ibáñez University, Santiago, Chile',
            country: '',
            years: [
                {
                    year: '2006',
                    program: 'PIAR Alumni',
                },
            ],
        },

        {
            name: 'Ms. Ornissa Velayadum',
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/testimonial/Ornissa-Velayadum.png',
            comment:
                'The level of rigor and participation was expectational, a combination of theoretical teachings and practical application. I would recommend this course to anyone working in this field.',
            position: 'Economist',
            company:
                'Policy Analyst: Infrastructure Finance National Treasury, South Africa',
            country: '',
            years: [
                {
                    year: '2019',
                    program: 'PIAR Alumni',
                },
            ],
        },

        {
            name: 'Mr. Paul Karekezi',
            imageUrl:
                'https://jdintl.econ.queensu.ca/uploads/testimonial/Paul-Karekezi.png',
            comment:
                'An exceptional learning experience! A well-designed program that I would highly recommend to anyone involved in designing projects .',
            position: '  Managing Director',
            company: 'GIBB Africa Ltd, Kenya',
            country: '',
            years: [
                {
                    year: '2018',
                    program: 'PIAR Alumni',
                },
                {
                    year: '2019',
                    program: 'PIAR ToT Alumni',
                },
            ],
        },
    ];

    public upComingPrograms = [
        {
            title: '',
            content: `<p>
Queen’s University was established by Royal Charter in 1841 and is one of Canada’s most distinguished universities. Since 2000, the PIAR has offered annually by the John Deutsch International Executive Programs of Queen’s University.
</p>`,
            center: 'Queen’s University in Kingston, Canada',
            location: 'Kingston, Canada',
            date: PROGRAM_DATE,
            imageUrls: [
                {
                    imageUrl:
                        'https://jdintl.econ.queensu.ca/uploads/queens.jpg',
                    title: 'Queen’s University',
                },
                {
                    imageUrl:
                        'https://jdintl.econ.queensu.ca/uploads/canada.png',
                    title: 'Canada',
                },
            ],
        },
    ];

    protected readonly PROGRAM_DATE = PROGRAM_DATE;

    ngOnInit(): void {}
}
