import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-news-letter-form',
  templateUrl: './widget-news-letter-form.component.html',
  styleUrls: ['./widget-news-letter-form.component.scss']
})
export class WidgetNewsLetterFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
