<div
    class="navbar-area"
    ngStickyNav
    stickyClass="sticky-box-shadow"
>
    <div class="container-fluid">
        <nav
            [class.active]="classApplied"
            class="navbar navbar-expand-lg navbar-light bg-light"
        >
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item">
                        <a (click)="toggleClass()"
                           class="nav-link"
                           routerLink="/"
                           routerLinkActive="active"
                        >Home</a>

                    </li>
                    <li class="nav-item">
                        <a (click)="toggleClass()"
                           class="nav-link"
                           routerLink="/about" routerLinkActive="active"
                        >About </a>
                    </li>
                    <li class="nav-item">
                        <a
                            (click)="toggleClass()"
                            class="nav-link"
                            routerLink="/discussion-papers"
                            routerLinkActive="active"
                        >Discussion Papers</a>
                    </li>
                    <li class="nav-item" title="Queen's Economics Department Working Papers">
                        <a
                            href="https://www.econ.queensu.ca/research/working-papers" target="_blank"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                        >QED Papers</a
                        >
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)"
                        >Training Programs
                            <i class="bx bx-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu">

                            <li class="nav-item">
                                <a
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/training/piar"
                                    routerLinkActive="active"
                                >
                                    Program on Investment Appraisal and Risk Analysis
                                </a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/training/training-of-trainers"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >Training of Trainers</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/training/specialized-programs"
                                    routerLinkActive="active"
                                >Specialized Programs</a
                                >
                            </li>

                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)"
                        >Alumni <i class="bx bx-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    (click)="toggleClass()"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/alumni/piar_at_queens_university"
                                    routerLinkActive="active"
                                >PIAR at Queen’s University
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    [queryParams]="{ct: 'certification_in_investment_appraisal_and_project_finance', type:'certification'}"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/alumni/ia-pf/crt"
                                    routerLinkActive="active"
                                >Certification in Investment Appraisal and Project Finance
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    [queryParams]="{ct: 'certification_in_investment_appraisal_risk_analysis', type:'certification'}"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/alumni/ia-ra/crt"
                                    routerLinkActive="active"
                                >
                                    Certification in Investment Appraisal & Risk Analysis
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    (click)="toggleClass()"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/alumni/pm_at_queens_university"
                                    routerLinkActive="active"
                                >PM at Queen’s University</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    (click)="toggleClass()"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/alumni/piam_at_harvard_university"
                                    routerLinkActive="active"
                                >PIAM at Harvard University</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/alumni/specialized_programs"
                                    routerLinkActive="active"
                                >Specialized Programs
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    class="nav-link"
                                    routerLink="/alumni-info/update-request"
                                    routerLinkActive="active"
                                >Information Update Request
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a
                            (click)="toggleClass()"
                            [routerLinkActiveOptions]="{ exact: true }"
                            class="nav-link"
                            routerLink="/news"
                            routerLinkActive="active"
                        >News</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            (click)="toggleClass()"
                            [routerLinkActiveOptions]="{ exact: true }"
                            class="nav-link"
                            routerLink="/contact"
                            routerLinkActive="active"
                        >Contact Us</a
                        >
                    </li>

                </ul>
            </div>
        </nav>
    </div>
</div>
