<div class="loader-wrapper" *ngIf="showLoading">
    <div id="loader"></div>
</div>
<ng-container *ngIf="news | async as newsData">

    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/news">News</a></li>
                    <li>{{newsData.news_title}}</li>
                </ul>
                <h2>{{newsData.news_title}}</h2>
            </div>
        </div>
        <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
    </div>

    <div class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="entry-meta mb-2">
                            <i class="bx bx-calendar"></i>
                            <span class="text-uppercase">Published Date:</span>
                            <span class="ms-2">{{newsData.news_date}}</span>
                        </div>
                        <div class="article-image">
                            <img [src]="newsData.news_main_image" alt="image" />
                            <caption>{{newsData.news_main_image_caption}}</caption>
                        </div>
                        <div class="article-content" [froalaView]="newsData.news_content">

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area">
                        <!--                        <app-widget-latest-news></app-widget-latest-news>-->
                        <app-widget-upcomming-events></app-widget-upcomming-events>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</ng-container>
