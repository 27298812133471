<div class="page-title-area" style="background-image: url('assets/img/about_us_bg.png');">
    <div class="container">
        <div class="page-title-content">
            <h2>Discussion Papers</h2>
            <div class="tw-text-center tw-mt-3">
                <a (click)="scroll(ddp)" class="default-btn tw-mr-3" href="javascript:void(0)">
                    Development Discussion Papers
                </a>

                <a href="https://www.econ.queensu.ca/research/working-papers" target="_blank"
                   class="default-btn tw-mt-3 md:tw-md-0">
                    Queen's Economics Department Working Papers
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                            d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</div>


<div class="about-area-two pb-100">
    <div class="container">
        <div style="border: 1px solid #CCCCCC;" id="ddp" #ddp>
            <iframe loading="lazy" src="https://jdintl.econ.queensu.ca/ddp/index.php" width="100%" height="500px"
                    style="height: 80vh"></iframe>
        </div>
        <div id="qed" #qed class="tw-mt-10">
            <div class="section-title">
                <h2 class="tw-text-[1.5rem]">
                    Queen's Economics Department Working Papers
                </h2>
                <p>
                    Go to <a class="flex" href="https://www.econ.queensu.ca/research/working-papers" target="_blank">
                    Working Papers
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path
                            d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                    </svg>
                </a>
                </p>
            </div>
        </div>
    </div>
</div>
