<div class="widget widget_odemy_posts_thumb">
    <h3 class="widget-title">Upcoming Programme</h3>
    <article class="item">
        <a routerLink="/training/piar" class="thumb">
            <span class="fullimage cover upcoming-img" role="img"></span>
        </a>
        <div class="info">
            <span>{{ PROGRAM_DATE }} </span>
            <h4 class="title usmall">
                <a routerLink="/training/piar"
                >Queen’s University</a
                >
                <span>Kingston, Canada</span>
            </h4>
        </div>
        <div class="clear"></div>
    </article>
</div>
