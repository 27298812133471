import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-specialized-programs',
  templateUrl: './specialized-programs.component.html',
  styleUrls: ['./specialized-programs.component.scss']
})
export class SpecializedProgramsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
