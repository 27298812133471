import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-of-trainers',
  templateUrl: './training-of-trainers.component.html',
  styleUrls: ['./training-of-trainers.component.scss']
})
export class TrainingOfTrainersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
