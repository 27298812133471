import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class DiscussionPaparsService {
    constructor(private apiService: ApiService) {}

    // tslint:disable-next-line:typedef
    fetchDiscussionPapers() {
        return this.apiService.get(
            'https://jdintl.econ.queensu.ca/ddp/api/index.php'
        );
    }
}
