import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';

export const DOMAIN_URL = 'https://jdintl.econ.queensu.ca';

@Injectable({
    providedIn: 'root',
})
export class NewsService {
    allNews = new BehaviorSubject([]);

    constructor(private apiService: ApiService) {}

    get news(): Observable<any> {
        // const dd = this.apiService.get('../../assets/news_data/news_data.json');
        // dd.subscribe((news) => {
        //     console.log(news);
        // });
        // return dd;
        return this.apiService.colWithIds$('news');
    }

    saveNews(news: any): Promise<void> {
        return this.apiService.upsert(`news/${news.id}`, news);
    }

    // tslint:disable-next-line:typedef
    setNew(news) {
        return this.apiService.add(`news`, news);
    }
}
