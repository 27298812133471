<div
    class="main-banner-area"
    style="
        background-image: url('https://jdintl.econ.queensu.ca/uploads/Autumn-Campus-and-Building-Views-36-d8oskn-anzk3y.png');
    "
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-content-style-two">
                    <h1>{{ PROGRAM_YEAR }} JDInt’l</h1>
                    <p class="tw-text-[1.5rem]">
                        Program on Investment Appraisal and Risk Analysis (PIAR)
                        {{ PROGRAM_YEAR }}
                        <br />
                        at Queen’s University, Kingston, Canada
                        <br />{{ PROGRAM_DATE }}
                    </p>
                    <a routerLink="/training/piar" class="default-btn"
                    >Program information<i
                        class="flaticon-right-chevron"
                    ></i
                    ><span></span
                    ></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-image-style-two">
                    <!--                    <img src="https://jdintl.econ.queensu.ca/wp-content/uploads/2021/04/Autumn-Campus-and-Building-Views-36-d8oskn-anzk3y.png" alt="image">-->
                </div>
            </div>
        </div>
    </div>
    <!--    <div class="bulb"><img src="assets/img/bulb.png" alt="image"></div>-->
</div>
