<div class="page-title-area" style="background-image: url('assets/img/about_us_bg.png');">

    <div class="container">
        <div class="page-title-content">
            <h2>
                Specialized Programs
            </h2>
        </div>
    </div>
</div>

<div class="courses-details-area pt-6 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="courses-details-desc-style-two">
                    <h3>Description</h3>
                    <p>
                        The objective of specialized programs is to focus on specific areas of project evaluation,
                        project management, and general economics. Specialized programs are developed and
                        implemented as requested by the sponsoring organization or government.
                    </p>
                    <p>
                        In most cases, specialized programs are offered in the host country. The creates an
                        opportunity to tailor the content of the program to address local capacity gaps, increasing
                        the program’s value. Some of the topics covered are listed below:
                    </p>
                    <ul class="description-features-list">
                        <li>
                            <strong
                            >Design and Evaluation of Public-Private Partnerships (PPP)</strong
                            >
                            – This program emphasizes economic and stakeholder analysis of projects undertaken on a
                            project financing basis. Firstly, the design and risk implications of alternative security
                            arrangements used in such financing structures are considered in detail. Secondly, the types
                            of risks faced by projects in different sectors are identified. Thirdly, participants become
                            familiar with the modern techniques of risk mitigation for such project financing
                            arrangements. Finally, the characteristics and types of risk facing financial institutions
                            that are inherent to alternative special purpose vehicle contracting or concession
                            arrangements are discussed
                        </li>
                        <li>
                            <strong>Appraisal of Electricity Generation, Transmission, and Distribution
                                Projects</strong>
                            – A reliable electricity sector, is one of the most important needs for any economy in
                            today’s world. Often, governments in developing countries (even oil rich) fail to invest in
                            the right technology or capacity. This results in significant financial and social costs for
                            the economy. An integrated evaluation of investment in the power sector can identify gaps
                            and solutions for a system of electricity generation, distribution, and transmission.
                            <br>
                            <br>
                            In response to rising oil prices and concerns for the environment, many countries have
                            invested in renewable sources of energy. While many viable renewable sources exist, failing
                            to carefully integrate them into the pervading systems of electricity generation can lead to
                            higher generation costs, compromising the reliability of distribution.
                            <br>
                            <br>
                            Such topics are thoroughly covered and applied in a course that is focused on Appraisal of
                            Electricity Generation, Transmission, and Distribution Projects.
                        </li>
                        <li>
                            <div>
                                <strong>Integrated Appraisal of Transportation Projects</strong> – The following topics
                                are
                                covered in a program on transportation projects:
                                <ul class="requirements-list">
                                    <li>Techniques available for evaluating the costs and benefits of transportation for
                                        the
                                        consumer
                                    </li>
                                    <li>Estimation of social costs associated with congestion</li>
                                    <li>Integration of movements in different parameters affecting the demand for
                                        transportation
                                    </li>
                                    <li>Toll roads</li>
                                    <li>Integration of the&nbsp;impacts&nbsp;on/from alternative and complimentary means
                                        of
                                        transportation
                                    </li>
                                    <li>Alternative evaluation and ranking techniques for rural and low traffic roads
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <strong>Cost-Effectiveness Analysis of Health, Nutrition, and Education Programs</strong>
                            –It is almost impossible to evaluate projects aimed at improving health, nutrition, or
                            education, solely by using conventional cost-benefit analysis. The main challenge is
                            assigning a monetary value to the benefits earned from these projects. However, alternative
                            methods exist, which help identify the most economically viable option for achieving a given
                            target, or disbursing a budget efficiently. This program focuses on cost-effectiveness
                            analysis, and other tools for ranking and evaluating such investment projects.
                        </li>
                        <li>
                            <strong>Tax Analysis and Revenue Forecasting</strong>
                            <p>
                                –Based on the local context, the following topics can be covered in a program on Tax
                                Analysis and Revenue Forecasting:
                            </p>
                            <ul class="requirements-list">
                                <li>International trade taxation and agreements</li>
                                <li>International tax harmonization</li>
                                <li>VAT and excise taxes</li>
                                <li>Consumption and expenditure taxes</li>
                                <li>Natural resources taxes</li>
                                <li>Personal income tax</li>
                                <li>Corporate income tax</li>
                                <li>Property taxes</li>
                                <li>Tax reform</li>
                                <li>Revenue forecasting</li>
                                <li>Modelling&nbsp;tax and customs administration</li>
                                <li>Public finance.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Natural Resources Governance and Management</strong>
                            <p>
                                – Topics included in a program on Natural Resources Governance and Management are
                                grouped
                                under three categories: Legal and Policy, Contracting, and Fiscal Management. Other
                                important issues that are also covered in such programs include Socio-Economic Impacts
                                and Environmental Management.
                            </p>
                        </li>
                        <li>
                            <strong>Project Management</strong>
                            <p>
                                –Through this program, participants learn about the modern Project Management processes
                                and
                                knowledge areas, as well as obtain a global perspective of recent developments in these
                                fields. It is completely in-line with PMI®’s globally recognized PMBOK® Guide, fifth
                                edition. The program emphasizes application in a number of sectors, including water
                                supply,
                                road construction, hospital/health care, housing, operations planning, new plant
                                development
                                and environmental control.
                            </p>
                            Topics covered:
                            <ul class="requirements-list">
                                <li>Project management process groups and knowledge areas</li>
                                <li>Integration management</li>
                                <li>Scope management</li>
                                <li>Time management</li>
                                <li>Cost management</li>
                                <li>Quality management</li>
                                <li>Human resource management</li>
                                <li>Communications management</li>
                                <li>Risk management</li>
                                <li>Procurement management</li>
                                <li>Stakeholder management</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
