<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        John Deutsch International
                    </a>
                    <p>
                        John Deutsch International (JDInt’l) at Queen’s
                        University is an international leader in designing and
                        delivering executive training programs for professionals
                        in the public and private sectors worldwide. Since 2000,
                        over 1,000 public and private sector professionals from
                        50 countries have successfully completed its intensive
                        on-campus and international programs.
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Quick Links</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li>
                            <a routerLink="/online-application"
                            >Online Application</a
                            >
                        </li>
                        <li>
                            <a routerLink="/discussion-papers"
                            >Discussion Papers</a
                            >
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.econ.queensu.ca/research/working-papers"
                            >QED Papers</a
                            >
                        </li>
                        <li><a routerLink="/contact">Contact us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <em class="bx bx-phone-call"></em>
                            <div>
                                Queen's University Dunning Hall, Department of
                                Economics, <br />
                                Room 216C, 94 University Avenue, <br />
                                Kingston, Ontario K7L 3N6, Canada
                            </div>
                        </li>
                        <li>
                            <em class="bx bx-phone-call"></em>
                            <a href="tel:+17037226040 ">+1 206 360 0800 </a>
                        </li>
                        <li>
                            <em class="bx bxl-whatsapp"></em
                            ><a target="_blank" href="https://wa.me/16137707226"
                        >+1 613 770 7226</a
                        >
                        </li>
                        <li>
                            <em class="bx bx-envelope"></em
                            ><a href="mailto:piar@queensu.ca" target="_blank"
                        >piar@queensu.ca</a
                        >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>
                        <i class="bx bx-copyright"></i>
                        {{ currentYear }}
                        JDInt’l
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
