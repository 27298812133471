<div class="page-title-area" style="background-image: url('assets/img/about_us_bg.png');">
    <div class="container-fluid">
        <div class="page-title-content">
            <h2>
                Training of Trainers
            </h2>
            <!-- <div class="rating">
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <div class="rating-count">
                     <span>4.0 (1 rating)</span>
                 </div>
             </div>-->
        </div>
    </div>
</div>

<div class="courses-details-area pb-100">
    <!-- <div class="courses-details-image">
         <img alt="image" src="assets/img/courses/course-details.jpg"/>
     </div>-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Overview">
                            <div class="courses-overview">
                                <p>
                                    Development of local training capacity is the most cost-effective approach for
                                    building successful and sustainable PPP and public investment programs.
                                </p>
                                <p>
                                    Training of Trainers (ToT) is a stream of the Program on Investment Appraisal and
                                    Risk Analysis (PIAR), offered exclusively to professionals who have previously
                                    undergone training in investment appraisal.
                                </p>

                                <h3>ToT curriculum covers:</h3>
                                <ul class="requirements-list">
                                    <li>Advanced lectures on modelling, presentation, and pedagogical skills</li>
                                    <li>
                                        Intensive applied work on the Integrated analysis of a real investment project
                                    </li>
                                </ul>
                                <p>
                                    The emphasis of ToT is on deepening understanding through personal tutoring, so
                                    participants may be confident training and advising on the appraisal of investment
                                    projects. Most ToT participants take advantage of the PIAR lectures, however, they
                                    are not required to attend topics in which they are knowledgeable.
                                </p>
                                <p>
                                    Faculty will work closely with the participants to complete a comprehensive
                                    feasibility study, preferably of a current project from their home office. It is
                                    recommended (but not necessary) that participants have a project in advance so that
                                    faculty can ensure all the required information for the project is available.
                                </p>

                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <h3>
                                            Benefits for the participants
                                        </h3>
                                        <ul class="requirements-list">
                                            <li>
                                                Improved modelling and presentation skills
                                            </li>
                                            <li>
                                                Ability to plan and deliver training programs
                                            </li>
                                            <li>
                                                Access to a database of resources and lecture notes for in-house
                                                education
                                            </li>
                                        </ul>
                                        <h3>
                                            Benefits for the organization
                                        </h3>


                                        <ul class="requirements-list">
                                            <li>
                                                Develops management skills for leading investment appraisal teams

                                            </li>
                                            <li>
                                                Creates local training capacity

                                            </li>
                                            <li>
                                                Is an effective method for completing the feasibility study of
                                                prospective projects

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    <div class="image">
                        <img alt="image" src="assets/img/courses/Applynow.png" />

                    </div>

                    <div class="info">
                        <!-- <span class="sub-title">To be announced</span>-->
                        <h3>How to apply</h3>

                        <p>
                            Applications may be submitted electronically via our website:
                        </p>
                        <p>
                            To ensure smooth communication, please provide a
                            postal address and a street address, for both home
                            and office (where different), office and home phone
                            and fax numbers wherever possible, and an email
                            address, if available.
                        </p>

                    </div>
                    <div class="content info"></div>
                    <div class="btn-box">
                        <a class="default-btn" routerLink="/online-application">
                            Apply Now
                            <span></span>
                        </a>

                    </div>
                    <!--<div class="courses-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>
                            <ul class="social-link">
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
