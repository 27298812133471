import { Component, OnInit } from '@angular/core';
import { DiscussionPaparsService } from '../../../services/discussionPapars/discussion-papars.service';

@Component({
    selector: 'app-discussion-papers',
    templateUrl: './discussion-papers.component.html',
    styleUrls: ['./discussion-papers.component.scss'],
})
export class DiscussionPapersComponent implements OnInit {
    constructor(private discussionPapersService: DiscussionPaparsService) {}

    ngOnInit(): void {
        this.discussionPapersService
            .fetchDiscussionPapers()
            .subscribe((papers) => {
                console.log(papers);
            });
    }

    public scroll(el: HTMLElement): void {
        el.scrollIntoView();
    }
}
