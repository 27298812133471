// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    firebase: {
        projectId: 'jdiatqueens',
        appId: '1:825700477133:web:83955e6c21f0202def2184',
        storageBucket: 'jdiatqueens.appspot.com',
        locationId: 'europe-central2',
        apiKey: 'AIzaSyAPR5k56aKD9tD6VfCRrbIYnHyhPe_igUs',
        authDomain: 'jdiatqueens.firebaseapp.com',
        messagingSenderId: '825700477133',
        measurementId: 'G-XRXKGHXVWE',
    },
    firebaseTwo: {
        apiKey: 'AIzaSyCoFIUQwGurZsDWnQukNF0ldYO6ke3T1xM',
        appId: '1:1046702029254:web:68735fb8837f9caa675ac7',
        authDomain: 'cri-jdi-queens.firebaseapp.com',
        measurementId: 'G-8FY2V1KK62',
        messagingSenderId: '1046702029254',
        projectId: 'cri-jdi-queens',
        storageBucket: 'cri-jdi-queens.appspot.com',
    },
    firebaseThree: {
        apiKey: 'AIzaSyCUTVkwb_W3PXR8YY_FsVEtrbKc6_LJh68',
        authDomain: 'cri-world-cb3c9.firebaseapp.com',
        projectId: 'cri-world-cb3c9',
        storageBucket: 'cri-world-cb3c9.appspot.com',
        messagingSenderId: '976705364721',
        appId: '1:976705364721:web:8729a02a58ae9536cd3f50',
        measurementId: 'G-VXZ0728TNJ',
    },
    useEmulators: false,
    production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
