<div class="widget widget_odemy_posts_thumb mb-4">
    <h3 class="widget-title">Related News</h3>
    <article class="item">
        <a routerLink="/single-blog-1" class="thumb">
                                <span
                                    class="fullimage cover bg1"
                                    role="img"
                                ></span>
        </a>
        <div class="info">
            <span>June 10, 2020</span>
            <h4 class="title usmall">
                <a routerLink="/single-blog-1"
                >Ultimate Bali Guide + Where to stay in
                    Bali 2020</a
                >
            </h4>
        </div>
        <div class="clear"></div>
    </article>
    <article class="item">
        <a routerLink="/single-blog-1" class="thumb">
                                <span
                                    class="fullimage cover bg2"
                                    role="img"
                                ></span>
        </a>
        <div class="info">
            <span>June 21, 2020</span>
            <h4 class="title usmall">
                <a routerLink="/single-blog-1"
                >Live the Island life: 20 unique Islands
                    to visit in 2020</a
                >
            </h4>
        </div>
        <div class="clear"></div>
    </article>
    <article class="item">
        <a routerLink="/single-blog-1" class="thumb">
                                <span
                                    class="fullimage cover bg3"
                                    role="img"
                                ></span>
        </a>
        <div class="info">
            <span>June 30, 2020</span>
            <h4 class="title usmall">
                <a routerLink="/single-blog-1"
                >Best Places to Visit in Europe this
                    Autumn & Winter</a
                >
            </h4>
        </div>
        <div class="clear"></div>
    </article>
</div>
