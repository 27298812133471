<div class="page-title-area" style="background-image: url('assets/img/about_us_bg.png');">
    <div class="container-fluid">
        <div class="page-title-content">
            <!--  <ul>
                  <li><a routerLink="/">Home</a></li>
                  <li><a routerLink="/training">Training Programs</a></li>
                  <li>
                      Financial Institutions for Private Enterprise Growth (FIPEG)
                  </li>
              </ul>-->


            <h2>
                <span class="sub-title">To be announced</span>
                Financial Institutions for Private Enterprise Growth (FIPEG)</h2>
            <!-- <div class="rating">
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <i class='bx bxs-star'></i>
                 <div class="rating-count">
                     <span>4.0 (1 rating)</span>
                 </div>
             </div>-->
        </div>
    </div>
</div>

<div class="courses-details-area pb-100">
    <!-- <div class="courses-details-image">
         <img alt="image" src="assets/img/courses/course-details.jpg"/>
     </div>-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Overview">
                            <div class="courses-overview">
                                <p>
                                    Queen’s University is offering its cutting-edge program on Financial Institutions
                                    for Private Enterprise Growth (FIPEG) for professionals who wish to learn about the
                                    latest innovations and strategies in the area of small and medium enterprise (SME)
                                    finance. Based on international best practices, this two-week program provides
                                    professionals with the new operational techniques, banking business models, and
                                    innovations that are required to build and maintain a successful SME banking
                                    portfolio. Furthermore, the program provides participants with the relationship
                                    management tools for SME customer satisfaction and will create awareness of the
                                    opportunities and threats arising from fintech companies. The program also gives
                                    senior government officials an understanding of the macroeconomic policies needed
                                    for sustainable SME finance.
                                </p>
                                <h3>Goals</h3>
                                <p>
                                    The goal of the program is to provide financial institutions with the strategies
                                    they need to penetrate the SME market and introduce new management skills and tools
                                    to finance SMEs’ growth on a sustainable and profitable basis. The program also aims
                                    to give government officials an understanding of the macroeconomic policies needed
                                    to promote the financing of SMEs by banks.
                                </p>

                                <h3>Who Should Participate?</h3>
                                <p>
                                    FIPEG has been designed for professionals in financial institutions,
                                    Fin-Techgovernment
                                    agencies, NGOs, and international organizations that recognize the potential and
                                    importance of financing SMEs.
                                </p>
                                <p>
                                    These include:
                                </p>
                                <ul class="requirements-list">
                                    <li>Commercial, investment and specialized banks</li>
                                    <li>
                                        Financial development corporations
                                    </li>
                                    <li>
                                        Central banks
                                    </li>
                                    <li>
                                        Government ministries
                                    </li>
                                    <li>
                                        Multilateral and bilateral development assistance agencies
                                    </li>
                                    <li>
                                        Non-governmental organizations
                                    </li>
                                    <li>
                                        Banking sector consultants and trainers
                                    </li>
                                </ul>

                                <h3>
                                    Study Method
                                </h3>
                                <p>
                                    The program consists of core lectures, case studies, group discussions, and
                                    presentations of successful country experiences. FIPEG has been designed to immerse
                                    participants in an interactive teaching and learning approach in which they learn
                                    how to tackle the challenging issues associated with serving SMEs in their work
                                    environment.
                                </p>

                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <h4>
                                            Queen’s University
                                        </h4>
                                        <p>
                                            The program takes place on the historic campus of Queen’s University in
                                            Canada.
                                            Kingston, Ontario, Queen’s University was established by a Royal Charter
                                            issued by
                                            Queen Victoria in 1841. It is one of Canada’s most distinguished and
                                            prestigious
                                            universities.
                                        </p>
                                        <h4>
                                            Historic Kingston, Ontario
                                        </h4>
                                        <p>
                                            Kingston is located on Lake Ontario, mid-way between Toronto and Montreal,
                                            and close
                                            to Ottawa, the national capital. Kingston was established in 1669 and was
                                            the first
                                            capital of Canada. The city has a rich architectural heritage and
                                            spectacular
                                            waterfront location, making it one of Canada’s premier tourist destinations.
                                        </p>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <img
                                            src="https://jdintl.econ.queensu.ca/wp-content/uploads/2019/09/1-13-600x400.jpg"
                                            class="img-responsive" />
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Program Topics">
                            <div
                                class="
                                    courses-curriculum
                                    courses-details-desc-style-two
                                "
                            >
                                <p>
                                    <strong>
                                        The curriculum covers how to profitably penetrate the SME market, forms of SME
                                        finance, risk management, and the policy framework for SME finance.
                                    </strong>
                                </p>

                                <h3>PENETRATION OF SME MARKET:</h3>
                                <ul class="requirements-list">
                                    <li>
                                        Digitalization of SME finance
                                    </li>
                                    <li>
                                        Market penetration through fintechs
                                    </li>
                                    <li>
                                        Digitalization and customer experience management
                                    </li>
                                    <li>
                                        Open banking and partnering with fintechs
                                    </li>
                                    <li>
                                        Developing customized financial products and services
                                    </li>
                                    <li>
                                        Providing non-financial services to SMEs
                                    </li>
                                </ul>
                                <h3>FORMS OF SME FINANCE:</h3>
                                <ul class="requirements-list">
                                    <li>
                                        Alternative financing in lending
                                    </li>
                                    <li>
                                        Agricultural lending and value chain finance
                                    </li>
                                    <li>
                                        Financing women entrepreneurs

                                    </li>
                                    <li>
                                        Financing fast-growing innovative SMEs

                                    </li>
                                    <li>
                                        Financing digitalization of SMEs

                                    </li>
                                    <li>
                                        Financing start-ups: Venture capital for SMEs
                                    </li>

                                </ul>
                                <h3>RISK MANAGEMENT:</h3>
                                <ul class="requirements-list">
                                    <li>
                                        New tech for AML/KYC and other compliance

                                    </li>
                                    <li>
                                        Platforms for digitization of banking

                                    </li>
                                    <li>
                                        Risk management tools in SME finance

                                    </li>
                                    <li>
                                        Managing non-performing SME loans

                                    </li>
                                    <li>
                                        Fintech companies: opportunities and threats for banks
                                    </li>
                                </ul>
                                <h3>POLICY FRAMEWORK:</h3>
                                <ul class="requirements-list">

                                    <li>
                                        Data access and governance

                                    </li>
                                    <li>
                                        Policies for data localization and use of cloud

                                    </li>
                                    <li>
                                        Regulation and licensing of alternative financiers

                                    </li>
                                    <li>
                                        Government policies and access to finance
                                    </li>
                                </ul>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Upcoming Programs">
                            <div class="upcoming-program">
                                <h2>
                                    To be announced
                                </h2>
                            </div>
                        </ngx-tab>

                        <ngx-tab tabTitle="Admissions, Costs, and Funding">
                            <div class="courses-overview admission-cost-funding">

                                <h3>
                                    Costs and Funding
                                </h3>
                                <p>
                                    The program fee is US$8,900 per participant. This fee includes tuition, housing,
                                    health
                                    insurance, course materials, and social events, but does not include the cost of
                                    airfare
                                    and meals.
                                </p>

                                <h3>
                                    Sponsorship Studying in Douglas Library
                                </h3>
                                <p>
                                    Sponsorship is normally provided by the participant’s employer or by other funding
                                    organizations. Agencies that offer financial assistance for training include: UNDP
                                    (United Nations Development Program), UNIDO (United Nations Industrial Development
                                    Organization), World Bank, International Finance Corporation (IFC), Inter-American
                                    Development Bank (IDB), Asian Development Bank (ADB), and African Development Bank
                                    (AfDB).
                                </p>

                                <h3>
                                    Admissions Policy
                                </h3>
                                <p>
                                    The program is open to persons holding a university degree in economics, finance,
                                    management, accounting, banking, or a related discipline. Prior work experience is
                                    taken
                                    into consideration.
                                </p>


                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    <div class="image">
                        <img alt="image" src="assets/img/courses/Website-PIAR-750x500.jpg" />

                        <!--                         <div class="content">-->
                        <!--                             <i class="flaticon-play"></i>-->
                        <!--                             <span>Course Preview</span>-->
                        <!--                         </div>-->
                    </div>

                    <div class="info">
                        <span class="sub-title">To be announced</span>
                        <h3>How to apply</h3>

                        <p>
                            Applications may be submitted electronically via our website:
                        </p>
                        <p>
                            To ensure smooth communication, please provide a
                            postal address and a street address, for both home
                            and office (where different), office and home phone
                            and fax numbers wherever possible, and an email
                            address, if available.
                        </p>
                        <p>
                            For questions: fipeg [at] econ.queensu.ca
                        </p>
                    </div>
                    <div class="content info"></div>
                    <div class="btn-box">
                        <a class="default-btn" routerLink="/online-application">
                            Download Our Brochure
                            <span></span>
                        </a>


                    </div>
                    <!--<div class="courses-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>
                            <ul class="social-link">
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-instagram'></i></a>
                                </li>
                                <li><a class="d-block" href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
