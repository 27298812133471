import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    classApplied = false;

    constructor() {}

    ngOnInit(): void {}

    toggleClass(): void {
        this.classApplied = !this.classApplied;
    }
}
