import { Component, ElementRef, OnInit } from '@angular/core';
import { MailerService } from '../../../../services/mailer/mailer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { FileUploadService } from '../../../../services/fileUpload/file-upload.service';
import { FileUpload } from '../../../../models/file-upload';
import { finalize } from 'rxjs/operators';
import { PROGRAM_DATE } from '../../../../services/api/api.service';

@Component({
    selector: 'app-online-application',
    templateUrl: './online-application.component.html',
    styleUrls: ['./online-application.component.scss'],
})
export class OnlineApplicationComponent implements OnInit {
    protected readonly PROGRAM_DATE = PROGRAM_DATE;
    genderOptions = [
        'Male',
        'Female',
        'Prefer not to say',
        'Prefer to self-describe',
    ];

    constructor(
        private mailerService: MailerService,
        private formBuilder: FormBuilder,
        private fileUploadService: FileUploadService,
        private el: ElementRef
    ) {
        this.applicationForm = this.formBuilder.group({
            program: [
                `2025 Investment Appraisal and Risk Analysis (${this.PROGRAM_DATE}) Canada`,
                Validators.required,
            ],
            stream: ['', Validators.required],
            title: [''],
            firstName: ['', Validators.required],
            middleName: [''],
            lastName: ['', Validators.required],
            jobTitle: ['', Validators.required],
            organization: ['', Validators.required],
            citizenship: ['', Validators.required],
            dateOfBirth: ['', Validators.required],
            gender: ['', Validators.required],
            selfDescribe: [''],
            fileCv: ['', Validators.required],
            picture: ['', Validators.required],
            mobileTel: ['', Validators.required],
            email: ['', Validators.required],
            addressType: ['', Validators.required],
            address: ['', Validators.required],
            mailingAddressType: ['', Validators.required],
            mailingAddress: ['', Validators.required],
            funding: ['', Validators.required],
            tandm: ['', Validators.required],
            referrer: ['', Validators.required],
            officeTel: [''],
            homeTel: [''],
            fax: [''],
            altMail: [''],
            employment: [' '],
            education: [''],
            transcript: [''],
            specify: [''],
        });

        this.applicationForm.get('gender')?.valueChanges.subscribe((value) => {
            if (value === 'Prefer to self-describe') {
                this.applicationForm
                    .get('selfDescribe')
                    ?.setValidators([Validators.required]);
            } else {
                this.applicationForm.get('selfDescribe')?.clearValidators();
            }
            this.applicationForm.get('selfDescribe')?.updateValueAndValidity();
        });
    }

    public programs = [
        {
            name: '2024 Investment Appraisal and Risk Analysis (July 8th to August 2nd, 2024) Canada',
            id: '2024 Investment Appraisal and Risk Analysis (July 8th to August 2nd, 2024) Canada',
        },
    ];
    public streams = [
        {
            name: 'Regular',
            id: 'Regular',
        },
        {
            name: 'Training of Trainers (Available for PIAR alumni)',
            id: 'Training of Trainers (Available for PIAR alumni)',
        },
    ];
    public uploader: FileUploader = new FileUploader({});
    applicationForm: FormGroup;
    transcript;
    fileCv;
    picture;
    attachments = {};
    attachmentsUrl = {};
    showLoading: boolean;
    applicationSent = false;

    ngOnInit(): void {}

    getFiles(): FileLikeObject[] {
        return this.uploader.queue.map((fileItem) => {
            return fileItem.file;
        });
    }

    onChange(event, type: string): void {
        this.attachments[type] = event.target.files[0];
        switch (type) {
            case 'transcript':
                this.transcript = event.target.files[0];

                break;
            case 'fileCv':
                this.fileCv = event.target.files[0];
                break;
            case 'picture':
                this.picture = event.target.files[0];
        }
    }

    public toggleSameAsAddress(event: Event): void {
        const isChecked = (event.target as HTMLInputElement).checked;

        if (isChecked) {
            const address = this.applicationForm.get('address')?.value;
            const addressType = this.applicationForm.get('addressType')?.value;
            this.applicationForm.patchValue({
                mailingAddressType: addressType?.trim() || '',
                mailingAddress: address?.trim() || '',
            });
        } else {
            this.applicationForm.patchValue({
                mailingAddressType: '',
                mailingAddress: '',
            });
        }
    }

    //  private basePath = 'files';
    private async uploadFiles(): Promise<any> {
        const promises = [];
        Object.keys(this.attachments).forEach((key) => {
            const promise = new Promise((resolve, reject) => {
                const file = new FileUpload(this.attachments[key]);
                const fileName = this.fileUploadService.renameFile(
                    file.file.name
                );
                const filePath = `${this.fileUploadService.basePath}/${fileName}`;
                const rep = this.fileUploadService
                    .pushFileToStorage(file, filePath)
                    .pipe(
                        finalize(() => {
                            this.fileUploadService
                                .getDownloadURL(filePath, file)
                                .subscribe(
                                    (downloadURL) => {
                                        file.url = downloadURL;
                                        file.name = file.file.name;
                                        file.type = key;
                                        this.attachmentsUrl[key] = file.url;
                                        resolve(this.attachmentsUrl);
                                    },
                                    (error) => {
                                        reject(key);
                                    }
                                );
                        })
                    );
                rep.subscribe(
                    (res) => {
                        if (res.state === 'success') {
                            console.log(res);
                        }
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    }
                );
            });
            promises.push(promise);
        });
        return Promise.all(promises);
    }

    public async submitApplication(): Promise<void> {
        if (this.applicationForm.invalid) {
            this.applicationForm.markAllAsTouched();
            this.scrollToFirstInvalidControl();
        } else {
            this.showLoading = true;
            const data = this.prepareForm();
            console.log(data);
            await this.uploadFiles()
                .then(async (res) => {
                    const attachments = {
                        ...res[0],
                    };
                    const docId = await this.mailerService.saveApplicationForm(
                        {
                            ...data,
                            status: 'pending',
                        },
                        attachments
                    );

                    this.mailerService
                        .sendApplicationForm(data, attachments)
                        .subscribe(
                            (response) => {
                                if (response?.ok === 'true') {
                                    this.showLoading = false;
                                    this.applicationSent = true;
                                    this.mailerService.updateApplication(docId);
                                } else {
                                    this.showLoading = false;
                                    this.applicationSent = false;
                                }
                                this.mailerService.sendThankYouMessage(data);
                            },
                            (error) => {
                                this.showLoading = false;
                                this.applicationSent = false;

                                console.log(error);
                            }
                        );
                })
                .catch((err) => {
                    this.showLoading = false;
                    this.applicationSent = false;
                    alert(
                        'An error occurred while sending the application form. Please try again, or contact us if the problem persists.'
                    );
                })
                .finally(() => {
                    console.log('complete');
                });
        }
    }

    private prepareForm(): any {
        const formData = this.applicationForm.value;

        const preparedData = {};
        const excluded = [
            'fileCv',
            'picture',
            'transcript',
            'tandm',
            'selfDescribe',
        ];
        Object.keys(formData).forEach((key: any) => {
            if (!excluded.includes(key)) {
                preparedData[key] =
                    key === 'gender' &&
                    formData[key] === 'Prefer to self-describe'
                        ? formData.selfDescribe
                        : formData[key];
            }
        });
        return preparedData;
        // for Debugging
        /*  return {
              program: formData.program,
              stream: formData.st,
              name: 'derek jamabo',
              title: 'werw',
              organization: 'wrwrwr',
              citizenship: 'wrwrwrw',
              dateOfBirth: '2021-11-19',
              gender: 'Female',
              fileCv: 'C:\\fakepath\\Les05.ppt',
              picture: 'C:\\fakepath\\RVR ESIA Exec Summary_English final.pdf',
              mailingAddress: 'an University, Famagusta K.K.T.C Mersin 10 Turkey',
              streetAddress: 'an University, Famagusta K.K.T.C Mersin 10 Turkey',
              additionalAddress:
                  'an University, Famagusta K.K.T.C Mersin 10 Turkey',
              officeTel: '5338369565',
              homeTel: 'wrwrwrwr',
              mobileTel: 'wrwrwrwr',
              fax: 'wrwrwrwrwrwr',
              email: 'derekjamabo@gmail.com',
              altMail: 'derekjamabo@gmail.com',
              employment: 'wrwrwrwr',
              education: 'wrwrwrwrwr',
              transcript: 'C:\\fakepath\\RVR ESIA Exec Summary_English final.pdf',
              funding: 'wrwrwr',
              referrer: 'Other',
              specify: 'wrwrwrwr',
              tandm: true,
          };*/
    }

    // tslint:disable-next-line:typedef
    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement =
            this.el.nativeElement.querySelector('form .ng-invalid');

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: 'smooth',
        });
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return (
            controlEl.getBoundingClientRect().top + window.scrollY - labelOffset
        );
    }
}
