<div class="loader-wrapper" *ngIf="showLoading">
    <div id="loader"></div>
</div>
<div
    class="page-title-area"
    style="background-image: url('assets/img/about_us_bg.png')"
>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Online Application</li>
            </ul>
            <h2>Online Application</h2>
        </div>
    </div>
</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div
                class="col-lg-10 col-md-12 card"
                [ngClass]="{ 'tw-border-0': applicationSent }"
            >
                <div
                    class="register-form card-body"
                    [class.hidden]="applicationSent"
                >
                    <div>
                        To ensure smooth communication, please provide your
                        postal/mailing address, home or office addresses (if
                        different), office and home phone numbers, fax numbers
                        (if possible), and an email address.
                    </div>
                    <hr class="hr tw-mt-[1rem] tw-mb-[1rem]" />
                    <div>
                        Please fill in your application, mandatory fields are
                        marked with <span class="text-danger">*</span>
                    </div>
                    <hr class="hr tw-mt-[1rem] tw-mb-[1rem]" />

                    <form
                        class="row g-2 justify-content-center"
                        novalidate
                        [formGroup]="applicationForm"
                    >
                        <div class="col-md-12">
                            <h3>Program</h3>
                            <label class="form-label">
                                Available Program
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                required
                                id="program"
                                type="text"
                                formControlName="program"
                                readOnly
                                class="form-control form-control-lg"
                            />
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['program']
                                        .invalid &&
                                    (applicationForm.controls['program']
                                        .dirty ||
                                        applicationForm.controls['program']
                                            .touched)
                                "
                            >
                                Please select a valid program.
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label>
                                Select a Stream
                                <span class="text-danger">*</span></label
                            >

                            <ng-container *ngFor="let stream of streams">
                                <div class="form-check">
                                    <input
                                        type="radio"
                                        class="form-check-input radio"
                                        id="radio-{{ stream.id }}"
                                        formControlName="stream"
                                        name="stream"
                                        value="{{ stream.id }}"
                                    />
                                    {{ stream.name }}
                                    <label
                                        class="form-check-label"
                                        for="radio-{{ stream.id }}"
                                    ></label>
                                </div>
                            </ng-container>
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['stream']
                                        .invalid &&
                                    (applicationForm.controls['stream'].dirty ||
                                        applicationForm.controls['stream']
                                            .touched)
                                "
                            >
                                Please select a valid stream.
                            </div>
                        </div>
                        <hr class="hr tw-mt-[2rem] tw-mb-[2rem]" />
                        <div class="col-md-12">
                            <h3>Personal Information</h3>
                            <div
                                class="col-md-3 col-sm-12 tw-mb-4 field-wrapper"
                            >
                                <label for="title" class="form-label">
                                    Title
                                </label>
                                <input
                                    id="title"
                                    placeholder="e.g., Mr., Mrs., Dr., etc."
                                    type="text"
                                    formControlName="title"
                                    class="form-control form-control-lg"
                                />
                                <div
                                    class="invalid-feedback"
                                    *ngIf="
                                        applicationForm.controls['title']
                                            .invalid &&
                                        (applicationForm.controls['title']
                                            .dirty ||
                                            applicationForm.controls['title']
                                                .touched)
                                    "
                                >
                                    Please enter your title
                                </div>
                            </div>
                            <div class="row field-wrapper">
                                <div class="col-md-4 col-sm-12 field-wrapper">
                                    <label for="firstName" class="form-label">
                                        First Name
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        required
                                        id="firstName"
                                        type="text"
                                        formControlName="firstName"
                                        class="form-control form-control-lg"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls[
                                                'firstName'
                                            ].invalid &&
                                            (applicationForm.controls[
                                                'firstName'
                                            ].dirty ||
                                                applicationForm.controls[
                                                    'firstName'
                                                ].touched)
                                        "
                                    >
                                        Please enter your first name
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-12 field-wrapper">
                                    <label for="middleName" class="form-label">
                                        Middle Name
                                    </label>
                                    <input
                                        id="middleName"
                                        type="text"
                                        formControlName="middleName"
                                        class="form-control form-control-lg"
                                    />
                                </div>

                                <div class="col-md-4 col-sm-12 field-wrapper">
                                    <label for="lastName" class="form-label">
                                        Last Name
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        required
                                        id="lastName"
                                        type="text"
                                        formControlName="lastName"
                                        class="form-control form-control-lg"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls['lastName']
                                                .invalid &&
                                            (applicationForm.controls[
                                                'lastName'
                                            ].dirty ||
                                                applicationForm.controls[
                                                    'lastName'
                                                ].touched)
                                        "
                                    >
                                        Please enter your last name
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 field-wrapper">
                            <label class="form-label" for="jobTitle">
                                Job Title / Position
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                id="jobTitle"
                                required
                                formControlName="jobTitle"
                                class="form-control form-control-lg"
                            />
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['jobTitle']
                                        .invalid &&
                                    (applicationForm.controls['jobTitle']
                                        .dirty ||
                                        applicationForm.controls['jobTitle']
                                            .touched)
                                "
                            >
                                Please enter a job title or position
                            </div>
                        </div>
                        <div class="col-md-12 field-wrapper">
                            <label class="form-label" for="Organization">
                                Organization <span class="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                required
                                id="Organization"
                                formControlName="organization"
                                class="form-control form-control-lg"
                            />
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['organization']
                                        .invalid &&
                                    (applicationForm.controls['organization']
                                        .dirty ||
                                        applicationForm.controls['organization']
                                            .touched)
                                "
                            >
                                Please enter an organization
                            </div>
                        </div>
                        <div class="col-md-12 field-wrapper">
                            <label class="form-label" for="citizenship">
                                Citizenship <span class="text-danger">*</span>
                            </label>
                            <input
                                required
                                type="text"
                                id="citizenship"
                                formControlName="citizenship"
                                class="form-control form-control-lg"
                            />
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['citizenship']
                                        .invalid &&
                                    (applicationForm.controls['citizenship']
                                        .dirty ||
                                        applicationForm.controls['citizenship']
                                            .touched)
                                "
                            >
                                Please enter your country
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 field-wrapper">
                            <label class="form-label" for="dob">
                                Date of Birth <span class="text-danger">*</span>
                            </label>
                            <input
                                type="date"
                                id="dob"
                                name="dateOfBirth"
                                formControlName="dateOfBirth"
                                required
                                class="form-control form-control-lg"
                            />
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['dateOfBirth']
                                        .invalid &&
                                    (applicationForm.controls['dateOfBirth']
                                        .dirty ||
                                        applicationForm.controls['dateOfBirth']
                                            .touched)
                                "
                            >
                                Please enter your date of birth
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 field-wrapper">
                            <label class="form-label" for="Gender">
                                Gender <span class="text-danger">*</span>
                            </label>
                            <select
                                required
                                id="Gender"
                                name="gender"
                                formControlName="gender"
                                class="form-select form-select-lg"
                                aria-label="gender field"
                            >
                                <option
                                    *ngFor="let option of genderOptions"
                                    [value]="option"
                                >
                                    {{ option }}
                                </option>
                            </select>
                            <ng-container
                                *ngIf="
                                    applicationForm.get('gender')?.value ===
                                    'Prefer to self-describe'
                                "
                            >
                                <div class="tw-mt-2.5">
                                    <label for="selfDescribe"
                                    >Please specify:</label
                                    >
                                    <input
                                        id="selfDescribe"
                                        type="text"
                                        formControlName="selfDescribe"
                                        class="form-control"
                                    />
                                </div>
                                <div
                                    class="invalid-feedback"
                                    *ngIf="
                                        applicationForm.controls['selfDescribe']
                                            .invalid &&
                                        (applicationForm.controls[
                                            'selfDescribe'
                                        ].dirty ||
                                            applicationForm.controls[
                                                'selfDescribe'
                                            ].touched)
                                    "
                                >
                                    Please select your gender
                                </div>
                            </ng-container>
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['gender']
                                        .invalid &&
                                    (applicationForm.controls['gender'].dirty ||
                                        applicationForm.controls['gender']
                                            .touched)
                                "
                            >
                                Please select your gender
                            </div>
                        </div>
                        <div class="col-md-12 field-wrapper">
                            <label class="form-label" for="picture"
                            >Profile Picture ( max: 5 MB)
                                <span class="text-danger">*</span>
                            </label>
                            <div class="input-group mb-3">
                                <input
                                    type="file"
                                    formControlName="picture"
                                    name="picture"
                                    accept=".png, .jpg, .jpeg"
                                    class="form-control form-control-lg"
                                    (change)="onChange($event, 'picture')"
                                    id="picture"
                                    required
                                />
                            </div>
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['picture']
                                        .invalid &&
                                    (applicationForm.controls['picture']
                                        .dirty ||
                                        applicationForm.controls['picture']
                                            .touched)
                                "
                            >
                                Please upload your picture
                            </div>
                        </div>
                        <div class="col-md-12 field-wrapper">
                            <label class="form-label" for="fileCv">
                                Please Upload your CV (format: pdf)
                                <span class="text-danger">*</span>
                            </label>
                            <div class="input-group">
                                <input
                                    type="file"
                                    formControlName="fileCv"
                                    accept="application/pdf"
                                    required
                                    class="form-control form-control-lg"
                                    (change)="onChange($event, 'fileCv')"
                                    id="fileCv"
                                />
                            </div>
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['fileCv']
                                        .invalid &&
                                    (applicationForm.controls['fileCv'].dirty ||
                                        applicationForm.controls['fileCv']
                                            .touched)
                                "
                            >
                                Please upload your CV
                            </div>
                        </div>
                        <hr class="hr tw-mt-[2rem] tw-mb-[2rem]" />
                        <div class="form-group">
                            <h3>Contact Information</h3>
                            <div class="row field-wrapper">
                                <div class="col-md-12 mb-3 field-wrapper">
                                    <label for="address" class="form-label">
                                        Address
                                        <span class="text-danger">*</span>
                                    </label>
                                    <select
                                        class="form-select form-select-lg"
                                        formControlName="addressType"
                                        required
                                    >
                                        <option value="" disabled selected>
                                            Select an address type
                                        </option>
                                        <option value="home">
                                            Home Address
                                        </option>
                                        <option value="work">
                                            Work Address
                                        </option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls[
                                                'addressType'
                                            ].invalid &&
                                            (applicationForm.controls[
                                                'addressType'
                                            ].dirty ||
                                                applicationForm.controls[
                                                    'addressType'
                                                ].touched)
                                        "
                                    >
                                        Please select an address type
                                    </div>
                                    <small class="field-description"
                                    >Please enter the full address(e.g.,
                                        street address, city, state, postal
                                        code).</small
                                    >
                                    <textarea
                                        required
                                        id="address"
                                        rows="4"
                                        name="address"
                                        formControlName="address"
                                        class="form-control form-control-lg"
                                    ></textarea>
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls['address']
                                                .invalid &&
                                            (applicationForm.controls['address']
                                                .dirty ||
                                                applicationForm.controls[
                                                    'address'
                                                ].touched)
                                        "
                                    >
                                        Please enter your address
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3 field-wrapper">
                                    <label
                                        for="mailingAddress"
                                        class="form-label"
                                    >
                                        Mailing Address
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div
                                        *ngIf="
                                            applicationForm.controls['address']
                                                .valid
                                        "
                                        class="col-md-12 tw-flex tw-align-middle tw-items-center field-wrapper"
                                    >
                                        <input
                                            type="checkbox"
                                            id="sameAsMailingAddress"
                                            (change)="
                                                toggleSameAsAddress($event)
                                            "
                                        />
                                        <label
                                            for="sameAsMailingAddress"
                                            class="tw-mb-0 tw-ml-3"
                                        >Address is same as above
                                            Address</label
                                        >
                                    </div>
                                    <select
                                        formControlName="mailingAddressType"
                                        class="form-select form-select-lg"
                                        required
                                    >
                                        <option value="" disabled selected>
                                            Select an address type
                                        </option>
                                        <option value="home">
                                            Home Address
                                        </option>
                                        <option value="work">
                                            Work Address
                                        </option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls[
                                                'mailingAddressType'
                                            ].invalid &&
                                            (applicationForm.controls[
                                                'mailingAddressType'
                                            ].dirty ||
                                                applicationForm.controls[
                                                    'mailingAddressType'
                                                ].touched)
                                        "
                                    >
                                        Please select mailing address type
                                    </div>
                                    <small class="field-description"
                                    >Please enter the full address where you
                                        receive mail (e.g., street address,
                                        city, state, postal code).</small
                                    >
                                    <textarea
                                        required
                                        id="mailingAddress"
                                        rows="4"
                                        name="mailingAddress"
                                        formControlName="mailingAddress"
                                        class="form-control form-control-lg"
                                    ></textarea>
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls[
                                                'mailingAddress'
                                            ].invalid &&
                                            (applicationForm.controls[
                                                'mailingAddress'
                                            ].dirty ||
                                                applicationForm.controls[
                                                    'mailingAddress'
                                                ].touched)
                                        "
                                    >
                                        Please enter your mailing address
                                    </div>
                                </div>
                                <div
                                    class="col-lg-6 col-md-12 mb-3 field-wrapper"
                                >
                                    <label for="officeTel" class="form-label">
                                        Office Telephone
                                    </label>
                                    <input
                                        id="officeTel"
                                        formControlName="officeTel"
                                        name="officeTel"
                                        type="text"
                                        class="form-control form-control-lg"
                                    />
                                </div>
                                <div
                                    class="col-lg-6 col-md-12 mb-3 field-wrapper"
                                >
                                    <label for="homeTel" class="form-label">
                                        Home Telephone
                                    </label>
                                    <input
                                        id="homeTel"
                                        type="text"
                                        formControlName="homeTel"
                                        name="homeTel"
                                        class="form-control form-control-lg"
                                    />
                                </div>
                                <div
                                    class="col-lg-6 col-md-12 mb-3 field-wrapper"
                                >
                                    <label for="mobileTel" class="form-label">
                                        Mobile Telephone
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        required
                                        id="mobileTel"
                                        name="mobileTel"
                                        formControlName="mobileTel"
                                        type="text"
                                        class="form-control form-control-lg"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls[
                                                'mobileTel'
                                            ].invalid &&
                                            (applicationForm.controls[
                                                'mobileTel'
                                            ].dirty ||
                                                applicationForm.controls[
                                                    'mobileTel'
                                                ].touched)
                                        "
                                    >
                                        This field is required
                                    </div>
                                </div>
                                <div
                                    class="col-lg-6 col-md-12 mb-3 field-wrapper"
                                >
                                    <label for="fax" class="form-label">
                                        Fax Number
                                    </label>
                                    <input
                                        id="fax"
                                        name="fax"
                                        formControlName="fax"
                                        type="text"
                                        class="form-control form-control-lg"
                                    />
                                </div>
                                <div
                                    class="col-lg-6 col-md-12 mb-3 field-wrapper"
                                >
                                    <label for="email" class="form-label">
                                        Email <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        required
                                        id="email"
                                        name="email"
                                        formControlName="email"
                                        type="email"
                                        class="form-control form-control-lg"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls['email']
                                                .invalid &&
                                            (applicationForm.controls['email']
                                                .dirty ||
                                                applicationForm.controls[
                                                    'email'
                                                ].touched)
                                        "
                                    >
                                        This field is required
                                    </div>
                                </div>
                                <div
                                    class="col-lg-6 col-md-12 mb-3 field-wrapper"
                                >
                                    <label for="altMail" class="form-label">
                                        Alternative Email Address
                                    </label>
                                    <input
                                        id="altMail"
                                        formControlName="altMail"
                                        name="altMail"
                                        type="email"
                                        class="form-control form-control-lg"
                                    />
                                </div>
                            </div>
                        </div>

                        <hr class="hr tw-mt-[2rem] tw-mb-[2rem]" />

                        <div class="form-group">
                            <h3>Education and Employment</h3>
                            <div class="row">
                                <div class="col-md-12 mb-3 field-wrapper">
                                    <label for="employment" class="form-label">
                                        Brief Description of Current Employment
                                    </label>
                                    <textarea
                                        id="employment"
                                        rows="4"
                                        formControlName="employment"
                                        class="form-control form-control-lg"
                                        placeholder="Employer, position, responsibilities, date of appointment, etc"
                                    ></textarea>
                                </div>
                                <div class="col-md-12 mb-3 field-wrapper">
                                    <label for="education" class="form-label">
                                        Education Summary
                                    </label>
                                    <textarea
                                        id="education"
                                        rows="4"
                                        formControlName="education"
                                        class="form-control form-control-lg"
                                        placeholder="Level and fields of higher level and professional education"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <hr class="hr tw-mt-[2rem] tw-mb-[2rem]" />
                        <div class="form-group">
                            <h3>Source of Funding</h3>
                            <div class="row">
                                <div class="col-md-12 mb-3 field-wrapper">
                                    <label for="funding" class="form-label">
                                        Planned sources of funds for tuition,
                                        travel and other expenses associated
                                        with the program
                                        <span class="text-danger">*</span>
                                    </label>
                                    <textarea
                                        required
                                        id="funding"
                                        rows="4"
                                        name="funding"
                                        formControlName="funding"
                                        class="form-control form-control-lg"
                                        placeholder="Employer, position, responsibilities, date of appointment, etc"
                                    ></textarea>
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls['funding']
                                                .invalid &&
                                            (applicationForm.controls['funding']
                                                .dirty ||
                                                applicationForm.controls[
                                                    'funding'
                                                ].touched)
                                        "
                                    >
                                        This field is required
                                    </div>
                                </div>
                                <div class="col-md-12 mb-3 field-wrapper">
                                    <label class="form-label" for="referrer">
                                        How did you learn about our program?

                                        <span class="text-danger">*</span>
                                    </label>
                                    <select
                                        required
                                        id="referrer"
                                        name="referrer"
                                        formControlName="referrer"
                                        class="form-select form-select-lg"
                                        aria-label="Default select example"
                                    >
                                        <option value="Alumni">Alumni</option>
                                        <option value="Employer">
                                            Employer
                                        </option>
                                        <option value="Program Brochure">
                                            Program Brochure
                                        </option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls['referrer']
                                                .invalid &&
                                            (applicationForm.controls[
                                                'referrer'
                                            ].dirty ||
                                                applicationForm.controls[
                                                    'referrer'
                                                ].touched)
                                        "
                                    >
                                        This field is required
                                    </div>
                                </div>
                                <div class="col-md-12 field-wrapper">
                                    <label for="specify" class="form-label">
                                        If other, please specify
                                    </label>
                                    <input
                                        id="specify"
                                        formControlName="specify"
                                        name="specify"
                                        type="text"
                                        class="form-control form-control-lg"
                                    />
                                </div>
                            </div>
                        </div>
                        <label class=""
                        >Confirmation
                            <span class="text-danger">*</span></label
                        >
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                required
                                formControlName="tandm"
                                value=""
                                id="flexCheckDefault"
                            />
                            <label
                                class="form-check-label"
                                for="flexCheckDefault"
                            >
                                I/We hereby confirm that the information
                                provided herein is accurate, correct and
                                complete and that the documents submitted along
                                with this application form are genuine.
                            </label>
                        </div>
                        <button
                            (click)="submitApplication()"
                            class="tw-mt-[30px] submit-btn"
                            type="submit"
                        >
                            Submit
                        </button>
                    </form>
                </div>
                <div
                    class="card-body"
                    [ngClass]="{ 'tw-hidden': !applicationSent }"
                >
                    <div class="card-img-top">
                        <img
                            ngSrc="assets/img/thankyou.png"
                            class="tw-m-auto"
                            title="thankyou"
                            alt="thank you"
                            height="422"
                            width="722"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
