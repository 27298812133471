<div
    class="navbar-area"
    ngStickyNav
    stickyClass="sticky-box-shadow"
>
    <div class="container-fluid">
        <nav
            class="navbar navbar-expand-lg navbar-light bg-light"
            [class.active]="classApplied"
        >
            <a class="navbar-brand" routerLink="/"
            ><img
                src="https://jdintl.econ.queensu.ca/uploads/cropped-JDIntl-Website-Header-Logo-Published.png"
                alt="logo"
            /></a>
            <button
                class="navbar-toggler"
                type="button"
                (click)="toggleClass()"
            >
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a routerLink="" class="nav-link">Home </a>
                    </li>
                    <li class="nav-item">
                        <a
                            routerLink="/about"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                        >About</a
                        >
                    </li>

                    <li class="nav-item">
                        <a
                            routerLink="/discussion-papers"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                        >Discussion Papers</a
                        >
                    </li>
                    <li class="nav-item" title="Queen's Economics Department Working Papers">
                        <a
                            href="https://www.econ.queensu.ca/research/working-papers" target="_blank"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                        >QED Papers</a
                        >
                    </li>

                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                        >Training Programs
                            <i class="bx bx-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu">


                            <li class="nav-item">
                                <a
                                    routerLink="/training/piar"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >
                                    Program on Investment Appraisal and Risk Analysis (PIAR)
                                </a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/training/training-of-trainers"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >Training of Trainers</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/training/specialized-programs"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >Specialized Programs</a
                                >
                            </li>

                        </ul>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link"
                        >Alumni <i class="bx bx-chevron-down"></i>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="nav-item">
                                <a
                                    routerLink="/alumni/piar_at_queens_university"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >PIAR at Queen’s University
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/alumni/ia-pf/crt"
                                    class="nav-link"
                                    [queryParams]="{ct: 'certification_in_investment_appraisal_and_project_finance', type:'certification'}"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >Certification in Investment Appraisal and Project Finance
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/alumni/ia-ra/crt"
                                    [queryParams]="{ct: 'certification_in_investment_appraisal_risk_analysis', type:'certification'}"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >
                                    Certification in Investment Appraisal & Risk Analysis
                                </a>
                            </li>

                            <li class="nav-item">
                                <a
                                    routerLink="/alumni/pm_at_queens_university"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >PM at Queen’s University</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/alumni/piam_at_harvard_university"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >PIAM at Harvard University</a
                                >
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/alumni/specialized_programs"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >Specialized Programs
                                </a>
                            </li>
                            <li class="nav-item">
                                <a
                                    routerLink="/alumni-info/update-request"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                >Information Update Request
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                 <a
                                     routerLink="/about-4"
                                     class="nav-link"
                                     routerLinkActive="active"
                                     [routerLinkActiveOptions]="{ exact: true }"
                                 >Alumni Information Update Request</a
                                 >
                             </li>-->
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a
                            routerLink="/news"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                            (click)="toggleClass()"
                        >News</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            routerLink="/contact"
                            class="nav-link"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: true }"
                        >Contact Us</a
                        >
                    </li>

                </ul>

                <!--MOBILE-->

            </div>
            <!--<div class="others-option d-flex align-items-center">

                <div class="option-item">
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Login/Register<span></span></a>
                </div>
            </div>-->
        </nav>
    </div>
</div>
<app-mobile-nav [classApplied]="classApplied"></app-mobile-nav>
