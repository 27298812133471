import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NewsService } from '../../../services/news/news.service';
import { ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';

@Component({
    selector: 'app-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss'],
})
export class NewsDetailComponent implements OnInit {
    news: Observable<any>;
    showLoading = true;
    public img = {
        src: '',
    };

    constructor(
        private newsService: NewsService,
        private route: ActivatedRoute,
        public lightbox: Lightbox
    ) {}

    open(src: string, caption): void {
        const album = {
            src,
            caption,
            thumb: src,
        };
        // open lightbox
        this.lightbox.open([album], 0);
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            const m = this.newsService.news;
            m.subscribe((s) => {
                if (s && params.slug) {
                    const sd = s.find((i) => i.news_slug === params.slug);
                    this.news = of(sd);
                    this.img.src = sd.news_main_image;
                }
                this.showLoading = false;
            });
        });
    }
}

@Pipe({
    name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
    transform(value: any[], order: 'asc' | 'desc' = 'asc'): any[] {
        console.log(value);
        return value.sort((a, b) => {
            if (order === 'asc') {
                return a.order_no - b.order_no;
            } else if (order === 'desc') {
                return b.order_no - a.order_no;
            }
            return 0;
        });
    }
}
