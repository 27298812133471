<div class="about-area ptb-100 bg-f9fbff">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h2>APPLICATIONS ARE NOW OPEN</h2>
                    <p class="text-align-justify justify-text">
                        With the recent surge of infections worldwide associated
                        with the Delta variant of the COVID-19 virus, and the
                        frequent changes in the restrictions imposed by the
                        governments on travel, it has become a challenge for
                        many applicants in 2021 to attend PIAR in Canada. As a
                        result, we have decided to hold the program of
                        Investment Appraisal and Risk Analysis in three
                        locations during 2021 and 2022. Queen’s PIAR faculty
                        team will be conducting the course in all locations. The
                        curriculum and the method of training will be identical
                        to that of the Regular and Training of Trainers Programs
                        normally held at the campus of Queen’s University. The
                        Certificate on Investment Appraisal and Risk Analysis
                        will be issued upon successful completion of the
                        program.
                    </p>
                    <span class="sub-title"> Upcoming programs:</span>

                    <ul class="features-list">
                        <li>
                            <span
                                >Ghana Institute of Management and Public
                                Administration (GIMPA) <br />– November 8 to
                                December 3, 2021</span
                            >
                        </li>
                        <li>
                            <span>
                                Cyprus International University in North Cyprus
                                (TRNC) <br />– March 7 to April 1, 2022</span
                            >
                        </li>
                        <li>
                            <span
                                >Queen’s University in Kingston, Canada <br />
                                – July 4 to July 29, 2022</span
                            >
                        </li>
                    </ul>
                    <a routerLink="/courses-1" class="default-btn"
                        ><i class="flaticon-user"></i>View All Courses<span
                        ></span
                    ></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img12.png" alt="image" />
                </div>
            </div>
        </div>
    </div>
    <div class="bulb"><img src="assets/img/bulb.png" alt="image" /></div>
</div>
