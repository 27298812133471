import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, origin?: string): any[] {
        if (!items) {
            return [];
        }
        if (origin === 'cert') {
            if (!searchText) {
                return items;
            }
            searchText = searchText.toLocaleLowerCase();

            const searched = items.filter((item) => {
                return JSON.stringify(item)
                    .toLocaleLowerCase()
                    .includes(searchText);
            });
            return searched;
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLocaleLowerCase();

        return items.filter((it) => {
            return JSON.stringify(it).toLocaleLowerCase().includes(searchText);
        });
    }
}
