import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { StickyNavModule } from 'ng2-sticky-nav';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { AccordionModule } from 'ngx-accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';

import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { AboutStyleFourComponent } from './components/pages/about-style-four/about-style-four.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { CoursesDetailsStyleOneComponent } from './components/pages/courses-details-style-one/courses-details-style-one.component';
import { CoursesDetailsStyleTwoComponent } from './components/pages/courses-details-style-two/courses-details-style-two.component';
import { LearningManagementComponent } from './components/pages/learning-management/learning-management.component';
import { LearningBannerComponent } from './components/pages/learning-management/learning-banner/learning-banner.component';
import { LearningCoursesComponent } from './components/pages/learning-management/learning-courses/learning-courses.component';
import { LearningAboutComponent } from './components/pages/learning-management/learning-about/learning-about.component';
import { LearningBlogComponent } from './components/pages/learning-management/learning-blog/learning-blog.component';
import { LearningViewCoursesComponent } from './components/pages/learning-management/learning-view-courses/learning-view-courses.component';
import { LearningPremiumAccessComponent } from './components/pages/learning-management/learning-premium-access/learning-premium-access.component';
import { OnlineApplicationComponent } from './components/pages/forms/online-application/online-application.component';
import { DiscussionPapersComponent } from './components/pages/discussion-papers/discussion-papers.component';
import { AlumniComponent } from './components/pages/alumni/alumni.component';
import { AlumniProfilesComponent } from './components/pages/alumni/alumni-profiles/alumni-profiles.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { HttpClientModule } from '@angular/common/http';
import { FipegComponent } from './components/pages/fipeg/fipeg.component';
import { PiarComponent } from './components/pages/piar/piar.component';
import { PiamComponent } from './components/pages/piam/piam.component';
import { TrainingOfTrainersComponent } from './components/pages/training-of-trainers/training-of-trainers.component';
import { SpecializedProgramsComponent } from './components/pages/specialized-programs/specialized-programs.component';
import { ImgFallbackDirective } from './directives/fall-back-image.directive';
import { AlumniInformationUpdateRequestComponent } from './components/pages/forms/alumni-information-update-request/alumni-information-update-request.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import {
    LocationStrategy,
    NgOptimizedImage,
    PathLocationStrategy,
} from '@angular/common';
import { FeaturesStyleFourComponent } from './components/common/features-style-four/features-style-four.component';
import { NewsComponent } from './components/pages/news/news.component';
import { LatestNewsComponent } from './components/common/latest-news/latest-news.component';
import { BlogDetailsStyleOneComponent } from './components/pages/blog-details-style-one/blog-details-style-one.component';
import { LoginComponent } from './admin/pages/login/login.component';
import { WidgetLatestNewsComponent } from './components/common/widget-latest-news/widget-latest-news.component';
import { WidgetUpcommingEventsComponent } from './components/common/widget-upcomming-events/widget-upcomming-events.component';
import { WidgetNewsLetterFormComponent } from './components/common/widget-news-letter-form/widget-news-letter-form.component';
import 'froala-editor/js/plugins.pkgd.min.js';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import {
    NewsDetailComponent,
    OrderByPipe,
} from './components/pages/news-detail/news-detail.component';
import { MobileNavComponent } from './components/common/navbar/mobile-nav/mobile-nav.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';

const fireToUse = environment.firebaseThree; // firebaseTwo
// const fireToUse = environment.firebaseTwo; // firebaseTwo

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        ContactUsComponent,

        AboutStyleFourComponent,
        ErrorComponent,
        FeaturesStyleFourComponent,
        BlogDetailsStyleOneComponent,
        CoursesDetailsStyleOneComponent,
        CoursesDetailsStyleTwoComponent,

        LearningManagementComponent,
        LearningBannerComponent,
        LearningCoursesComponent,
        LearningAboutComponent,
        LearningBlogComponent,
        LearningViewCoursesComponent,
        LearningPremiumAccessComponent,
        OnlineApplicationComponent,
        DiscussionPapersComponent,
        AlumniComponent,
        AlumniProfilesComponent,
        FipegComponent,
        PiarComponent,
        PiamComponent,
        TrainingOfTrainersComponent,
        SpecializedProgramsComponent,
        ImgFallbackDirective,
        AlumniInformationUpdateRequestComponent,
        SearchFilterPipe,
        NewsComponent,
        LatestNewsComponent,
        LoginComponent,
        WidgetLatestNewsComponent,
        WidgetUpcommingEventsComponent,
        WidgetNewsLetterFormComponent,
        NewsDetailComponent,
        MobileNavComponent,
        OrderByPipe,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        CountUpModule,
        StickyNavModule,
        TabsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxScrollTopModule,
        LightboxModule,
        AccordionModule,
        LazyLoadImageModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        FormsModule,
        AngularFireModule.initializeApp(fireToUse),
        AngularFirestoreModule,
        provideFirebaseApp(() => initializeApp(fireToUse)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth()),
        provideDatabase(() => {
            return getDatabase();
        }),
        FileUploadModule,
        NgOptimizedImage,
    ],
    providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
    bootstrap: [AppComponent],
})
export class AppModule {}
