<div class="loader-wrapper" *ngIf="showLoading">
    <div id="loader"></div>
</div>
<div class="page-title-area" style="background-image: url('assets/img/about_us_bg.png');">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>News</li>
            </ul>
            <h2>News</h2>
        </div>
    </div>
    <!--    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let newsItem of news | async">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post-box">
                        <div class="post-image tw-flex">
                            <a routerLink="/news/{{newsItem.news_slug}}" class="tw-m-auto tw-h-[inherit]">
                                <img [src]="newsItem.news_main_image" alt="image" class="news-image">
                            </a>
                        </div>
                        <div class="post-content">
                            <a routerLink="/news/{{newsItem.news_slug}}" class="category">{{newsItem.news_category}}</a>
                            <h3 class="tw-min-h-[4rem]"><a
                                routerLink="/news/{{newsItem.news_slug}}">{{newsItem.news_title}}</a>
                            </h3>
                            <ul class="post-content-footer ">
                                <li class=" "><i
                                    class="flaticon-calendar position-relative me-2"></i><span> {{newsItem.news_date}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
