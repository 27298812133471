<div class="loader-wrapper" *ngIf="showLoading">
    <div id="loader"></div>
</div>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Information Update Request</li>
            </ul>
            <h2>Alumni Information Update Request</h2>
        </div>
    </div>
</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-12 card">

                <div
                    class="register-form card-body"
                    [class.hidden]="applicationSent"
                >

                    <form
                        class="row g-2 justify-content-center"
                        novalidate
                        (ngSubmit)="submitApplication()"
                        [formGroup]="applicationForm"
                    >
                        <div class="col-md-12">
                            <h3>Personal Information</h3>
                            <label for="fullName" class="form-label">
                                Full Name <span class="text-danger">*</span>
                            </label>
                            <input
                                required
                                id="fullName"
                                type="text"
                                formControlName="name"
                                class="form-control form-control-lg"
                            />
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['name'].invalid &&
                                    (applicationForm.controls['name']
                                        .dirty ||
                                        applicationForm.controls['name']
                                            .touched)
                                "
                            >
                                Please enter your full name
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label" for="title">
                                Title / Position
                                <span class="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                id="title"
                                required
                                formControlName="title"
                                class="form-control form-control-lg"
                            />
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['title'].invalid &&
                                    (applicationForm.controls['title'].dirty ||
                                        applicationForm.controls['title']
                                            .touched)
                                "
                            >
                                Please enter a title or position
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label" for="Organization">
                                Organization <span class="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                required
                                id="Organization"
                                formControlName="organization"
                                class="form-control form-control-lg"
                            />
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['organization']
                                        .invalid &&
                                    (applicationForm.controls['organization']
                                        .dirty ||
                                        applicationForm.controls['organization']
                                            .touched)
                                "
                            >
                                Please enter an organization
                            </div>
                        </div>
                        <div class="col-md-12">
                            <label class="form-label" for="picture"
                            >Profile Picture ( max: 5 MB)
                                <span class="text-danger">*</span>
                            </label>
                            <div class="input-group mb-3">
                                <input
                                    type="file"
                                    formControlName="picture"
                                    name="picture"
                                    accept=".png, .jpg, .jpeg"
                                    class="form-control form-control-lg"
                                    (change)="onChange($event, 'picture')"
                                    id="picture"
                                    required
                                />
                            </div>
                            <div
                                class="invalid-feedback"
                                *ngIf="
                                    applicationForm.controls['picture']
                                        .invalid &&
                                    (applicationForm.controls['picture']
                                        .dirty ||
                                        applicationForm.controls['picture']
                                            .touched)
                                "
                            >
                                Please upload your picture
                            </div>
                        </div>
                        <hr class="hr" />
                        <div class="form-group">
                            <h3>Contact Information</h3>
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <label
                                        for="mailingAddress"
                                        class="form-label"
                                    >
                                        Mailing Address
                                        <span class="text-danger">*</span>
                                    </label>
                                    <textarea
                                        id="mailingAddress"
                                        rows="4"
                                        name="mailingAddress"
                                        formControlName="mailingAddress"
                                        class="form-control form-control-lg"
                                        placeholder="Please specify work or home"
                                    ></textarea>
                                </div>

                                <div class="col-lg-6 col-md-12 mb-3">
                                    <label for="mobileTel" class="form-label">
                                        Phone number
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        id="mobileTel"
                                        name="mobileTel"
                                        formControlName="mobileTel"
                                        type="text"
                                        class="form-control form-control-lg"
                                    />

                                </div>
                                <div class="col-lg-6 col-md-12 mb-3">
                                    <label for="email" class="form-label">
                                        Email <span class="text-danger">*</span>
                                    </label>
                                    <input
                                        required
                                        id="email"
                                        name="email"
                                        formControlName="email"
                                        type="email"
                                        class="form-control form-control-lg"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        *ngIf="
                                            applicationForm.controls['email']
                                                .invalid &&
                                            (applicationForm.controls['email']
                                                .dirty ||
                                                applicationForm.controls[
                                                    'email'
                                                ].touched)
                                        "
                                    >
                                        This field is required
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr class="hr" />

                        <div class="form-group">
                            <h3>Education and Employment</h3>
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <label for="education" class="form-label">
                                        Education Summary
                                    </label>
                                    <textarea
                                        id="education"
                                        rows="4"
                                        formControlName="education"
                                        class="form-control form-control-lg"
                                        placeholder="Level and fields of higher level and professional education"
                                    ></textarea>
                                    <!-- <div class="invalid-feedback"
                                          *ngIf="applicationForm.controls['education'].invalid && (applicationForm.controls['education'].dirty || applicationForm.controls['education'].touched)">
                                         This field is required
                                     </div>-->
                                </div>
                            </div>
                            <hr class="hr" />

                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <label for="note" class="form-label">
                                        Note
                                    </label>
                                    <textarea
                                        id="note"
                                        rows="4"
                                        formControlName="education"
                                        class="form-control form-control-lg"
                                        placeholder=""
                                    ></textarea>
                                    <!-- <div class="invalid-feedback"
                                          *ngIf="applicationForm.controls['education'].invalid && (applicationForm.controls['education'].dirty || applicationForm.controls['education'].touched)">
                                         This field is required
                                     </div>-->
                                </div>
                            </div>
                        </div>
                        <hr class="hr" />
                        <label class=""
                        >Confirmation
                            <span class="text-danger">*</span></label
                        >
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                required
                                formControlName="tandm"
                                value=""
                                id="flexCheckDefault"
                            />
                            <label
                                class="form-check-label"
                                for="flexCheckDefault"
                            >
                                I/We hereby confirm that the information
                                provided herein is accurate, correct and
                                complete.
                            </label>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
                <div class="card-body" [class.hidden]="!applicationSent">
                    <div class="text-center">
                        <p>Thank you for submitting your update request</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
