<div class="loader-wrapper" *ngIf="showLoading">
    <div id="loader"></div>
</div>
<ng-container *ngIf="news | async as newsData">
    <div
        class="page-title-area"
        style="background-image: url('assets/img/about_us_bg.png')"
    >
        <div class="container">
            <div class="page-title-content">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/news">News</a></li>
                    <li>{{ newsData.news_title }}</li>
                </ul>
                <h2>{{ newsData.news_title }}</h2>
            </div>
        </div>
    </div>

    <div class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <ng-container>
                            <!--<div class="entry-meta mb-2">
                                <i class="bx bx-calendar"></i>
                                <span class="text-uppercase"
                                >Published Date:</span
                                >
                                <span class="ms-2">{{
                                    newsData.news_date
                                    }}</span>
                            </div>-->
                            <div class="article-image">
                                <img
                                    alt="group of people sitting on chairs"
                                    [ngSrc]="newsData.news_main_image"
                                    width="650"
                                    height="500"
                                    (click)="
                                        open(
                                            newsData.news_main_image,
                                            newsData.news_main_image_caption
                                        )
                                    "
                                />
                                <caption>
                                    {{
                                    newsData.news_main_image_caption
                                    }}
                                </caption>
                            </div>

                            <div class="news-title tw-font-bold tw-text-[21px]">
                                <h2>{{ newsData.news_title }}</h2>
                            </div>
                            <div
                                class="article-content tw-mb-[30px]"
                                [froalaView]="newsData.news_content"
                            ></div>
                            <ng-container
                                *ngIf="newsData?.program_testimonials"
                            >
                                <h2
                                    class="tw-font-bold tw-text-[21px] tw-mb-[25px] tw-mt-2"
                                >
                                    {{ newsData?.program_testimonials_title }}
                                </h2>
                                <ng-container
                                    *ngFor="
                                        let item of newsData.program_testimonials | orderBy : 'asc'
                                    "
                                >
                                    <div
                                        style="
                                            box-sizing: border-box;
                                            display: flex !important;
                                            flex-direction: row !important;
                                            align-items: center !important;
                                            color: rgb(65, 65, 65);
                                            font-family: sans-serif;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-variant-ligatures: normal;
                                            font-variant-caps: normal;
                                            font-weight: 400;
                                            letter-spacing: normal;
                                            orphans: 2;
                                            text-indent: 0px;
                                            margin-bottom: 23px;
                                            text-transform: none;
                                            white-space: normal;
                                            widows: 2;
                                            word-spacing: 0px;
                                            -webkit-text-stroke-width: 0px;

                                            text-decoration-thickness: initial;
                                            text-decoration-style: initial;
                                            text-decoration-color: initial;
                                            text-align: left;
                                        "
                                    >
                                        <div
                                            class="t-image-wrapper"
                                            style="
                                                flex-shrink: 0 !important;
                                                width: 205px;
                                                height: 260px;
                                                margin-right: 20px;
                                            "
                                        >
                                            <img
                                                ngSrc="{{
                                                    item.testimonial_image
                                                }}"
                                                (click)="
                                                    open(
                                                        item.testimonial_image,
                                                        item.testimonial_name
                                                    )
                                                "
                                                [alt]="item.testimonial_name"
                                                width="205"
                                                height="260"
                                                style="
                                                    width: 100%;
                                                    height: 260px;
                                                    object-fit: contain;
                                                    cursor: pointer;
                                                    padding: 0px 1px;
                                                    user-select: none;
                                                "
                                            />
                                        </div>
                                        <div
                                            style="
                                                flex-grow: 1 !important;
                                                margin-right: 1rem !important;
                                            "
                                        >
                                            <div
                                                class="testimonials-content tw-italic tw-text-[14px] tw-mb-2"
                                            >
                                                {{ item.testimonial_content }}
                                            </div>
                                            <div
                                                class="testimonials-info tw-font-semibold"
                                            >
                                                <div class="testimonials-name">
                                                    {{ item.testimonial_name }}
                                                </div>
                                                <div>
                                                    {{
                                                    item.testimonial_position
                                                    }}
                                                </div>
                                                <div class="t-organization">
                                                    {{
                                                    item.testimonial_company
                                                    }}
                                                </div>
                                                <div
                                                    class="testimonial_country"
                                                >
                                                    {{
                                                    item?.testimonial_country
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <aside class="widget-area">
                        <app-widget-upcomming-events></app-widget-upcomming-events>
                        <div class="tw-mt-[2rem]">
                            <!--                            <app-widget-latest-news></app-widget-latest-news>-->
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</ng-container>
