import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import firebase from 'firebase/compat';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AlumniService {
    private alumni: Observable<any>;

    constructor(private apiService: ApiService) {}

    getAlumniByProgram(programId: string): Observable<any> {
        console.log(programId);
        if (programId === 'certifications') {
            return this.apiService.colWithIds$('certificationAlumni');
        } else {
            return this.apiService.colWithIds$('alumni', (ref) => {
                let query: firebase.firestore.Query = ref;
                query = query.where(
                    'categorySlug',
                    '==',
                    programId.toLowerCase()
                );
                query = query.orderBy('year', 'desc');
                return query;
            });
        }
    }

    getProgramAlumni(path: string, type?: string): Observable<any> {
        console.log(type, path);
        if (type === 'certification') {
            return this.apiService.doc$('certificationAlumni/' + path);
            // return this.apiService.get('../../assets/data/' + 'cer.json');
        } else {
            return this.apiService.doc$('alumni/' + path);
        }
    }

    /*getJsonAlumni() {
        return this.apiService.get('../../assets/data/' + 'alumn-data.json');
    }*/

    getToT(year, type): Observable<any> {
        const fileName = `${year}_${type.toUpperCase()}_TOT.json`;
        return this.apiService.get('../../assets/data/' + fileName);
    }

    public updateAlumniData(data, key): Promise<void> {
        return this.apiService.upsert(key + '/' + data.id, data);
    }

    public updateAlumniCertData(data): Promise<void> {
        return this.apiService.upsert('certificationAlumni/' + data.id, data);
    }
}
