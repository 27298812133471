import { Component, OnInit } from '@angular/core';
import { AlumniService } from '../../../services/alumni/alumni.service';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

const DOMAIN = 'https://api.jdintl.cri-world.com/api/';

@Component({
    selector: 'app-alumni',
    templateUrl: './alumni.component.html',
    styleUrls: ['./alumni.component.scss'],
})
export class AlumniComponent implements OnInit {
    alumni: Observable<any[]>;
    pageTitle = '';
    programCategory = '';
    pageSubTitle = '';
    groupPicture: string;
    picturePath: string;
    type: string;
    searchTerm: string;

    constructor(
        private alumniService: AlumniService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            const alumni = this.alumniService.getAlumniByProgram(params?.type);

            alumni.subscribe((items: any[]) => {
                console.log(items);
                if (items) {
                    const program = items[0];
                    const ITEMS = items.map((i: any) => {
                        this.picturePath =
                            i.category.replaceAll('_', ' ').toUpperCase() +
                            '/' +
                            i?.year +
                            ' ' +
                            i?.type.toUpperCase();
                        if (!i.groupPicture.includes('://')) {
                            if (params.type === 'certifications') {
                                i.groupPicture = !i.groupPicture
                                    ? DOMAIN +
                                      'CERTIFICATIONS' +
                                      '/' +
                                      i?.type.toUpperCase() +
                                      '/' +
                                      i?.type.toUpperCase() +
                                      ' ' +
                                      'Group Picture.jpg'
                                    : DOMAIN +
                                      'CERTIFICATIONS' +
                                      '/' +
                                      i?.type.toUpperCase() +
                                      '/' +
                                      i.groupPicture;
                            } else {
                                i.groupPicture = !i.groupPicture
                                    ? DOMAIN +
                                      this.picturePath +
                                      '/' +
                                      i?.year +
                                      ' ' +
                                      i?.type.toUpperCase() +
                                      ' ' +
                                      'Group Picture.jpg'
                                    : DOMAIN +
                                      this.picturePath +
                                      '/' +
                                      i.groupPicture;
                            }
                        }

                        return i;
                    });

                    this.type = `${program?.type.toUpperCase()}`;
                    this.pageTitle = `${program?.type.toUpperCase()} ALUMNI`;
                    if (params.type === 'certifications') {
                        this.programCategory = 'Certifications';
                    } else {
                        this.programCategory = `${program?.category
                            .replaceAll('_', ' ')
                            .replace('queens', "Queen's")
                            .toUpperCase()} `;
                    }
                    this.alumni = of(ITEMS);
                }
            });

            /* this.alumniService.getJsonAlumni().subscribe((data) => {
                 console.log(data);
                 for (const k in data) {
                     if (data[k]) {
                         // console.log(data[k]);
                         //this.alumniService.updateAlumniData(data[k], 'alumni');
                     }
                 }
             });*/
        });
    }

    searchAlumni(searchEvent): void {
        const term = searchEvent.target?.value;
        console.log(term);
    }
}
