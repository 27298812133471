import { Component, OnInit } from '@angular/core';
import { PROGRAM_DATE } from '../../../services/api/api.service';

@Component({
    selector: 'app-widget-upcomming-events',
    templateUrl: './widget-upcomming-events.component.html',
    styleUrls: ['./widget-upcomming-events.component.scss'],
})
export class WidgetUpcommingEventsComponent implements OnInit {
    public readonly PROGRAM_DATE = PROGRAM_DATE;

    constructor() {}

    ngOnInit(): void {}
}
