import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
    menuItems = [
        {
            title: 'News',
            isSubMenuVisible: false,
            subMenu: [
                {
                    title: 'list',
                    link: 'news',
                },
                {
                    title: 'Create',
                    link: 'news/create',
                },
            ],
        },
        {
            title: 'Alumni Profiles',
            isSubMenuVisible: false,
            subMenu: [
                {
                    title: 'list',
                    link: 'alumni',
                },
                {
                    title: 'Create',
                    link: 'alumni/create',
                },
            ],
        },
    ];

    constructor(private authService: AuthService) {}

    toggleSubMenu(index: number): void {
        this.menuItems[index].isSubMenuVisible =
            !this.menuItems[index].isSubMenuVisible;
    }

    ngOnInit(): void {}

    logOut(): void {
        this.authService.logOut();
    }
}
