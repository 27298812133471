<div class="features-area tw-pt-[2rem] tw-pb-[2rem] bg-f9f9f9">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-md-7 col-sm-12">
                <div class="section-title tw-text-left tw-max-w-none">
                    <h2 class="tw-text-left tw-max-w-none">
                        APPLICATIONS ARE NOW OPEN
                    </h2>
                </div>
                <div>
                    <p class="text-justify-center tw-text-[1.3rem]">
                        We are pleased to announce that applications are now
                        open for the {{ PROGRAM_YEAR }} Program on Investment Appraisal and
                        Risk Analysis. Both Regular and Training of Trainers
                        programs will be held at
                        <strong>Queen’s University, Canada,</strong> from
                        <strong> {{ PROGRAM_DATE }} </strong>.
                    </p>
                    <p class="text-justify-center tw-text-[1.3rem]">
                        The Certificate on Investment Appraisal and Risk
                        Analysis will be issued upon successful completion of
                        the program.
                    </p>
                </div>
                <div class="apply-now-btn-wrapper tw-text-left tw-mt-[3rem]">
                    <a routerLink="/online-application" class="default-btn"
                    ><i class="flaticon-right-chevron"></i>Apply now<span
                    ></span
                    ></a>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="single-events-box card">
                    <div class="image">
                        <a
                            href="https://www.econ.queensu.ca/"
                            target="_blank"
                            class="d-block"
                        >
                            <img
                                ngSrc="assets/img/prgrm-loc/Theo-Hall-e1410982660781.jpg"
                                alt="program image"
                                height="450" width="590" />
                        </a>
                    </div>
                    <div class="content">
                        <h3>
                            <a
                                href="https://www.econ.queensu.ca/"
                                target="_blank"
                            >Queen’s University, Department of Economics
                            </a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
