import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-mobile-nav',
    templateUrl: './mobile-nav.component.html',
    styleUrls: ['./mobile-nav.component.scss'],
})
export class MobileNavComponent implements OnInit {
    @Input() classApplied = false;

    constructor() {}

    ngOnInit(): void {}

    toggleClass(): void {
        this.classApplied = !this.classApplied;
    }
}
