import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AdminComponent implements OnInit {
    isOpen = false;

    constructor(private authService: AuthService) {}

    toggleSidebar($event: any) {
        this.isOpen = !this.isOpen;
    }

    ngOnInit(): void {
        this.authService.isLoggedIn().then((result) => {
            this.isOpen = result;
        });
    }
}
