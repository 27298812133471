import { Component, OnInit } from '@angular/core';
import { NewsService } from 'src/app/services/news/news.service';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-blog-details-style-one',
    templateUrl: './blog-details-style-one.component.html',
    styleUrls: ['./blog-details-style-one.component.scss'],
})
export class BlogDetailsStyleOneComponent implements OnInit {
    news: Observable<any>;
    showLoading = true;

    constructor(
        private newsService: NewsService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            const m = this.newsService.news;
            m.subscribe((s) => {
                if (s && params.slug) {
                    this.news = of(s.find((i) => i.news_slug === params.slug));
                }
            });
            this.showLoading = false;
        });
    }
}
