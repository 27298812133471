import { Component, OnInit } from '@angular/core';
import { MailerService } from '../../../services/mailer/mailer.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
    constructor(private mailerService: MailerService) {}

    isMessageSent = false;
    bgImage = [
        {
            img: 'assets/img/register-shape.jpg',
        },
    ];

    ngOnInit(): void {}

    submit(form): void {
        this.mailerService.sendContactMessage(form).then((res) => {
            this.isMessageSent = true;
        });
    }
}
