import { Component, OnInit } from '@angular/core';
import { NewsService } from '../../../services/news/news.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-widget-latest-news',
    templateUrl: './widget-latest-news.component.html',
    styleUrls: ['./widget-latest-news.component.scss'],
})
export class WidgetLatestNewsComponent implements OnInit {
    relatedNew: Observable<any[]>;

    constructor(private newsService: NewsService) {}

    ngOnInit(): void {
        // this.newsService.g
    }
}
