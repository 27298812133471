import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { AboutStyleFourComponent } from './components/pages/about-style-four/about-style-four.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { CoursesDetailsStyleOneComponent } from './components/pages/courses-details-style-one/courses-details-style-one.component';
import { CoursesDetailsStyleTwoComponent } from './components/pages/courses-details-style-two/courses-details-style-two.component';
import { LearningManagementComponent } from './components/pages/learning-management/learning-management.component';
import { OnlineApplicationComponent } from './components/pages/forms/online-application/online-application.component';
import { DiscussionPapersComponent } from './components/pages/discussion-papers/discussion-papers.component';
import { AlumniComponent } from './components/pages/alumni/alumni.component';
import { AlumniProfilesComponent } from './components/pages/alumni/alumni-profiles/alumni-profiles.component';
import { PiarComponent } from './components/pages/piar/piar.component';
import { FipegComponent } from './components/pages/fipeg/fipeg.component';
import { TrainingOfTrainersComponent } from './components/pages/training-of-trainers/training-of-trainers.component';
import { SpecializedProgramsComponent } from './components/pages/specialized-programs/specialized-programs.component';
import { AdminComponent } from './admin/admin.component';
import { NewsComponent } from './components/pages/news/news.component';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { AlumniInformationUpdateRequestComponent } from './components/pages/forms/alumni-information-update-request/alumni-information-update-request.component';
import { LoginComponent } from './admin/pages/login/login.component';

const routes: Routes = [
    { path: '', component: LearningManagementComponent },

    { path: 'learning-management', component: LearningManagementComponent },
    { path: 'online-application', component: OnlineApplicationComponent },
    { path: 'about', component: AboutStyleFourComponent },
    { path: 'single-courses-1', component: CoursesDetailsStyleOneComponent },
    { path: 'single-courses-2', component: CoursesDetailsStyleTwoComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'contact', component: ContactUsComponent },
    { path: 'discussion-papers', component: DiscussionPapersComponent },
    { path: 'alumni/:type', component: AlumniComponent },
    {
        path: 'alumni-info/update-request',
        component: AlumniInformationUpdateRequestComponent,
    },
    {
        path: 'alumni/:type/:alumniProfiles',
        component: AlumniProfilesComponent,
    },
    {
        path: 'training/piar',
        component: PiarComponent,
    },
    {
        path: 'training/fipeg',
        component: FipegComponent,
    },
    {
        path: 'training/training-of-trainers',
        component: TrainingOfTrainersComponent,
    },
    {
        path: 'training/specialized-programs',
        component: SpecializedProgramsComponent,
    },
    {
        path: 'news',
        component: NewsComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    { path: 'news/:slug', component: NewsDetailComponent },
    // Here add new pages component
    { path: 'single-blog-2', component: NewsDetailComponent },
    {
        path: 'admin',
        component: AdminComponent,
        loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminModule),
    },

    { path: '**', component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
