<ng-container *ngIf="latestNews | async as latest">
    <div class="blog-area tw-pt-20 pb-100 tw-bg-[#fffff]">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"></span>
                <h2>Latest News</h2>

            </div>
            <div class="row justify-content-center">
                <ng-container *ngFor="let newsItem of latest | slice:0:3">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box">
                            <div class="post-image">
                                <a routerLink="/news/{{newsItem.news_slug}}" class="d-block">
                                    <img [src]="newsItem.news_main_image" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <a routerLink="/news/{{newsItem.news_slug}}"
                                   class="category">{{newsItem.news_category}}</a>
                                <h3 class="tw-min-h-[4rem]"><a
                                    routerLink="/news/{{newsItem.news_slug}}">{{newsItem.news_title}}</a>
                                </h3>
                                <ul class="post-content-footer ">
                                    <li class=" "><i
                                        class="flaticon-calendar position-relative me-2"></i><span> {{newsItem.news_date}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="col-lg-12 col-md-12">
                    <div class="blog-post-info">
                        <p>
                            <a routerlink="/news" href="/news"
                            >View all News</a
                            >
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
