import { Component, ElementRef, OnInit } from '@angular/core';
import { FileLikeObject, FileUploader } from 'ng2-file-upload';
import { FileUpload } from '../../../../models/file-upload';
import { finalize } from 'rxjs/operators';
import { MailerService } from '../../../../services/mailer/mailer.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploadService } from '../../../../services/fileUpload/file-upload.service';

@Component({
    selector: 'app-alumni-information-update-request',
    templateUrl: './alumni-information-update-request.component.html',
    styleUrls: ['./alumni-information-update-request.component.scss'],
})
export class AlumniInformationUpdateRequestComponent implements OnInit {
    public uploader: FileUploader = new FileUploader({});
    applicationForm: FormGroup;
    transcript;
    fileCv;
    picture;
    attachments = {};
    attachmentsUrl = {};
    showLoading: boolean;
    applicationSent: boolean;

    constructor(
        private mailerService: MailerService,
        private formBuilder: FormBuilder,
        private fileUploadService: FileUploadService,
        private el: ElementRef
    ) {
        this.applicationForm = this.formBuilder.group({
            name: ['', Validators.required],
            title: ['', Validators.required],
            organization: ['', Validators.required],
            picture: ['', Validators.required],
            email: ['', Validators.required],
            tandm: ['', Validators.required],
            mobileTel: [''],
            mailingAddress: [''],
            note: [''],
            education: [''],
        });
    }

    ngOnInit(): void {}

    getFiles(): FileLikeObject[] {
        return this.uploader.queue.map((fileItem) => {
            return fileItem.file;
        });
    }

    onChange(event, type): void {
        this.attachments[type] = event.target.files[0];
        switch (type) {
            case 'picture':
                this.picture = event.target.files[0];
        }
    }

    //  private basePath = 'files';
    private async uploadFiles(): Promise<any> {
        const promises = [];
        Object.keys(this.attachments).forEach((key) => {
            const promise = new Promise((resolve, reject) => {
                const file = new FileUpload(this.attachments[key]);
                const filePath = `infoUpdateRequest/${
                    file.file.name
                }${new Date().getTime()}`;
                const rep = this.fileUploadService
                    .pushFileToStorage(file, filePath)
                    .pipe(
                        finalize(() => {
                            this.fileUploadService
                                .getDownloadURL(filePath, file)
                                .subscribe(
                                    (downloadURL) => {
                                        file.url = downloadURL;
                                        file.name = file.file.name;
                                        file.type = key;
                                        this.attachmentsUrl[key] = file.url;
                                        resolve(this.attachmentsUrl);
                                    },
                                    (error) => {
                                        reject(key);
                                    }
                                );
                        })
                    );
                rep.subscribe(
                    (res) => {
                        if (res.state === 'success') {
                            console.log(res);
                        }
                    },
                    (error) => {
                        console.log(error);
                        reject(error);
                    }
                );
            });
            promises.push(promise);
        });
        return Promise.all(promises);
    }

    public async submitApplication(): Promise<void> {
        if (this.applicationForm.invalid) {
            this.applicationForm.markAllAsTouched();
            this.scrollToFirstInvalidControl();
        } else {
            this.showLoading = true;
            const data = this.prepareForm();

            await this.uploadFiles()
                .then((res) => {
                    const attachments = {
                        ...res[0],
                    };
                    this.mailerService.saveUpdateForm(data, attachments);
                    this.mailerService
                        .sendUpdateForm(data, attachments)
                        .subscribe(
                            (response) => {
                                this.showLoading = false;
                                this.applicationSent = true;
                            },
                            (error) => {
                                this.showLoading = false;
                                this.applicationSent = false;

                                console.log(error);
                            }
                        );
                })
                .catch((err) => {
                    this.showLoading = false;
                    this.applicationSent = false;
                    alert(
                        'An error occurred while uploading, please try again'
                    );
                })
                .finally(() => {
                    console.log('complete');
                });
        }
    }

    private prepareForm(): any {
        const formData = this.applicationForm.value;

        const preparedData = {};
        const excluded = ['fileCv', 'picture', 'transcript', 'tandm'];
        Object.keys(formData).forEach((key: any) => {
            if (!excluded.includes(key)) {
                preparedData[key] = formData[key];
            }
        });
        return preparedData;
    }

    // tslint:disable-next-line:typedef
    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement =
            this.el.nativeElement.querySelector('form .ng-invalid');

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: 'smooth',
        });
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return (
            controlEl?.getBoundingClientRect().top +
            window.scrollY -
            labelOffset
        );
    }
}
